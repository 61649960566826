import { AdePredictionItem } from "api/adepredictions";
import { fabric } from "fabric";
import { LineCoords } from "./Lines";

export default class Predictions {
  items: AdePredictionItem[];
  static Config = {
    color: "green",
    nearRange: 250
  };

  constructor(private canvas: fabric.Canvas) {
    this.items = [];
  }

  drawPredictionItem(
    item: AdePredictionItem,
    color: string,
    lockMovement = true
  ) {
    if (item.item.toLowerCase() !== "pipe") {
      const rectangle = new fabric.Rect({
        name: this.getDisplayName(item),

        left: item.x1,
        top: item.y1,
        width: item.width,
        height: item.height,

        stroke: color,
        fill: "",
        strokeWidth: 5,
        opacity: 0.7,

        lockMovementX: lockMovement,
        lockMovementY: lockMovement,
        lockScalingX: lockMovement,
        lockScalingY: lockMovement,
        lockUniScaling: lockMovement
      });
      rectangle.setControlsVisibility({ mtr: false });
      this.canvas.add(rectangle);
    }
  }

  drawPredictionItemNearLine(
    lineCoords: LineCoords,
    nearRange: number,
    item: AdePredictionItem,
    color: string,
    lockMovement = true
  ) {
    if (
      item.item.toLowerCase() !== "pipe" &&
      this.isItemNearLine(item, lineCoords, nearRange)
    ) {
      const rectangle = new fabric.Rect({
        name: this.getDisplayName(item),

        left: item.x1,
        top: item.y1,
        width: item.width,
        height: item.height,

        stroke: color,
        fill: "",
        strokeWidth: 5,
        opacity: 0.7,

        lockMovementX: lockMovement,
        lockMovementY: lockMovement,
        lockScalingX: lockMovement,
        lockScalingY: lockMovement,
        lockUniScaling: lockMovement
      });
      rectangle.setControlsVisibility({ mtr: false });
      this.canvas.add(rectangle);
    }
  }

  onLineAdded(line: LineCoords) {
    this.items.forEach((x) => {
      this.drawPredictionItemNearLine(
        line,
        Predictions.Config.nearRange,
        x,
        Predictions.Config.color,
        true
      );
    });
  }

  getSqrDistance(x: number, y: number, line: LineCoords) {
    var t =
      ((x - line.x1) * (line.x2 - line.x1) +
        (y - line.y1) * (line.y2 - line.y1)) /
      ((line.x2 - line.x1) ** 2 + (line.y2 - line.y1) ** 2);
    if (t < 0) t = 0;
    if (t > 1) t = 1;
    return (
      (line.x1 - x + t * (line.x2 - line.x1)) ** 2 +
      (line.y1 - y + t * (line.y2 - line.y1)) ** 2
    );
  }

  isPointNearLine(x: number, y: number, line: LineCoords, nearRange: number) {
    return this.getSqrDistance(x, y, line) < nearRange ** 2;
  }

  isItemNearLine(
    item: AdePredictionItem,
    lineCoords: LineCoords,
    nearRange: number
  ) {
    return (
      this.isPointNearLine(item.x1, item.y1, lineCoords, nearRange) ||
      this.isPointNearLine(item.x2, item.y2, lineCoords, nearRange)
    );
  }

  getDisplayName(item: AdePredictionItem) {
    return (
      item.item
        ? `ade_${item.item}_${item.itemNumber}`
        : `ade_${item.predictedClass}_${item.itemNumber}`
    ).replace(" ", "_");
  }
}
