import { Box, Typography } from "@mui/material";
import Title from "./Title";

type Props = {
  title: string;
  titleVariant?:
    | "button"
    | "caption"
    | "h1"
    | "h2"
    | "h3"
    | "h4"
    | "h5"
    | "h6"
    | "inherit"
    | "overline"
    | "subtitle1"
    | "subtitle2"
    | "body1"
    | "body2"
    | undefined;
  textUnderTitle: string;
};

const TitleWithSubtext = ({
  title,
  titleVariant = "h3",
  textUnderTitle
}: Props) => {
  return (
    <Title>
      <>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between"
          }}
        >
          <Typography variant={titleVariant}>{title}</Typography>
        </Box>
        <Typography color={"GrayText"}>{textUnderTitle}</Typography>
      </>
    </Title>
  );
};

export default TitleWithSubtext;
