import {
  closestCenter,
  DndContext,
  DragEndEvent,
  useSensor,
  useSensors
} from "@dnd-kit/core";
import { SortableContext } from "@dnd-kit/sortable";
import DeleteIcon from "@mui/icons-material/Delete";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import EditIcon from "@mui/icons-material/Edit";
import {
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { useDeleteDeviation, usePatchDeviation } from "../../../api/deviations";
import ConfirmationDialog from "../../../components/ConfirmationDialog";
import { Deviation } from "../../../types";
import { MouseSensor } from "../sensors";
import EditDeviationModal from "./EditDeviationModal";
import TableRowWrapper from "./TableRowWrapper";

type Props = {
  deviations: Deviation[];
  isEditable: boolean;
};

type RouteParams = {
  projectId: string;
};

export default function DraggableTable({ deviations, isEditable }: Props) {
  const sensors = useSensors(useSensor(MouseSensor));
  const [selectedDeviation, setSelectedDeviation] = useState<Deviation>();
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] =
    useState(false);
  const { projectId } = useParams<RouteParams>() as RouteParams;
  const { mutateAsync } = useDeleteDeviation(projectId);
  const { mutateAsync: patchDeviation } = usePatchDeviation(projectId);

  function handleDragEnd(event: DragEndEvent) {
    const { active, over } = event;

    if (active.id !== over!.id) {
      const activeDeviation = deviations.find((d) => d.id === active.id);
      const overDeviation = deviations.find((d) => d.id === over?.id);
      if (!activeDeviation || !overDeviation) {
        return deviations;
      }

      patchDeviation({
        deviationId: activeDeviation.id,
        deviationPatch: {
          sequence: overDeviation.sequence
        },
        event
      });
    }
  }

  return (
    <>
      <DndContext
        collisionDetection={closestCenter}
        onDragEnd={isEditable ? handleDragEnd : undefined}
        sensors={sensors}
      >
        <SortableContext items={deviations}>
          <TableContainer component={Paper}>
            <Table data-no-dnd={!isEditable ? "true" : ""}>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ width: "50px" }}></TableCell>
                  <TableCell sx={{ width: "100px" }}>Select</TableCell>
                  <TableCell sx={{ width: "150px" }}>Guideword</TableCell>
                  <TableCell sx={{ width: "150px" }}>Parameter</TableCell>
                  <TableCell sx={{ width: "auto" }}>Interpretation</TableCell>
                  <TableCell sx={{ width: "130px" }}></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {deviations.map((deviation, i) => (
                  <TableRowWrapper key={deviation.id} id={deviation.id}>
                    <TableCell>
                      {isEditable && (
                        <DragIndicatorIcon />
                      )}
                    </TableCell>
                    <TableCell>
                      <Checkbox
                        data-no-dnd="true"
                        checked={deviation.selected}
                        onClick={async () => {
                          await patchDeviation({
                            deviationId: deviation.id,
                            deviationPatch: {
                              selected: !deviation.selected
                            }
                          });
                        }}
                        disabled={!isEditable}
                      />
                    </TableCell>
                    <TableCell>{deviation.guideword?.name}</TableCell>
                    <TableCell>{deviation.parameter?.name}</TableCell>
                    <TableCell>{deviation.interpretation}</TableCell>
                    <TableCell data-no-dnd="true">
                      {isEditable && (
                        <IconButton
                          onClick={(event) => {
                            setSelectedDeviation(deviation);
                            setIsEditModalOpen(true);
                          }}
                        >
                          <EditIcon />
                        </IconButton>
                      )}
                      {!deviation.isDefault && isEditable && (
                        <IconButton
                          onClick={() => {
                            setSelectedDeviation(deviation);
                            setIsDeleteConfirmationOpen(true);
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      )}
                    </TableCell>
                  </TableRowWrapper>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </SortableContext>
      </DndContext>

      {selectedDeviation && isEditModalOpen && (
        <EditDeviationModal
          isOpen={isEditModalOpen}
          handleClose={() => {
            setIsEditModalOpen(false);
            setSelectedDeviation(undefined);
          }}
          deviation={selectedDeviation}
        />
      )}

      <ConfirmationDialog
        isOpen={isDeleteConfirmationOpen}
        handleClose={() => setIsDeleteConfirmationOpen(false)}
        title={"Delete deviation"}
        text={"Are you sure you want to delete deviation?"}
        onAgree={() => {
          mutateAsync(selectedDeviation!.id);
          setIsDeleteConfirmationOpen(false);
        }}
      />
    </>
  );
}
