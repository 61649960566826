import axios, { AxiosError } from "axios";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { handleError } from "../helpers/handleError";
import { ProjectAddModel, ProjectViewModel } from "../types";

export const useGetProjects = (assetId: string) => {
  return useQuery(`projects-${assetId}`, async () => {
    const { data } = await axios.get<ProjectViewModel[]>(
      `/api/assets/${assetId}/projects`
    );
    return data;
  },
  {
    onError: handleError<unknown>
  });
};

export const useGetProject = (assetId: string, projectId: string) => {
  return useQuery<ProjectViewModel, AxiosError<undefined>>(
    ["projects", assetId, projectId],
    async () => {
      const { data } = await axios.get<ProjectViewModel>(
        `/api/assets/${assetId}/projects/${projectId}`
      );
      return data;
    },
    {
      onError: handleError<unknown>
    }
  );
};

// Partial is for badly generated type from backend.
export const addProject = async (
  assetId: number,
  newProject: Partial<ProjectAddModel>
) => {
  const { data: participant } = await axios.post<ProjectViewModel>(
    `/api/assets/${assetId}/project`,
    newProject
  );
  return participant;
};

export const useAddProject = (assetId: number) => {
  const queryClient = useQueryClient();

  return useMutation(
    (newProject: Partial<ProjectAddModel>) => addProject(assetId, newProject),
    {
      onError: handleError<unknown>,
      onSuccess: () => {
        toast.success("Successfully added new Project.");
        queryClient.invalidateQueries("organizationsWithAssetsAndProjects");
      }
    }
  );
};
