import HubIcon from "@mui/icons-material/Hub";
import ListAltIcon from "@mui/icons-material/ListAlt";
import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline";
import SettingsIcon from "@mui/icons-material/Settings";
import { Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import ProjectDetailLinkItem from "./ProjectDetailLinkItem";

const hazopPrepItems = [
  {
    title: "Participants",
    text: "Add and manage workshop participants",
    icon: <PeopleOutlineIcon />,
    to: "participants"
  },
  {
    title: "Workshop Schedule",
    text: "Set workshop time, add and manage workshop",
    icon: <ListAltIcon />,
    to: "workshop/schedule"
  },
  {
    title: "Study Setup",
    text: "Add drawings, set properties, templates, deciation lists and risk matrices",
    icon: <SettingsIcon />,
    to: "studysetup/properties"
  },
  {
    title: "Node Analysis",
    text: "Analyse P&IDs, mark nodes and predict deviation causes and consequences",
    icon: <HubIcon />,
    to: "nodeanalysis/nodes"
  }
];

const HazopPreparation = () => {
  return (
    <>
      <Typography variant={"h4"} mb={1}>
        Hazop Preparation
      </Typography>
      <Grid container spacing={2}>
        {hazopPrepItems.map((item) => (
          <ProjectDetailLinkItem {...item} key={item.to} />
        ))}
      </Grid>
    </>
  );
};

export default HazopPreparation;
