import axios from "axios";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { handleError } from "../helpers/handleError";
import { DocumentCategoriesInfo, FileMetaData } from "../types";

export const uploadFiles = async (
  projectId: string,
  documentCategoryId: string,
  files: File[]
) => {
  const formData = new FormData();
  files.forEach((file, i) => formData.append(`file ${i}`, file));
  const { data: organization } = await axios.post<unknown>(
    `/api/studysetup/${projectId}/upload/${documentCategoryId}`,
    formData
  );
  return organization;
};

export const useUploadFiles = (
  projectId: string,
  documentCategoryId: string
) => {
  const queryClient = useQueryClient();

  return useMutation(
    (files: File[]) => uploadFiles(projectId, documentCategoryId, files),
    {
      onError: (error: { response: { data?: string; statusText: string } }) => {
        toast.error(
          `Error: ${error.response?.data || error.response.statusText}`,
          { autoClose: false, hideProgressBar: true }
        );
      },
      onSuccess: () => {
        toast.success("Successfuly uploaded new files");
        queryClient.invalidateQueries([
          "study-setup-uploaded-files",
          documentCategoryId
        ]);
        queryClient.invalidateQueries([
          "study-setup-uploaded-files",
          projectId,
          documentCategoryId
        ]);
        queryClient.invalidateQueries(["documentcategories", projectId]);
      }
    }
  );
};

export const useGetUploadedFiles = (
  projectId: string,
  documentCategoryId: string
) => {
  return useQuery(
    ["study-setup-uploaded-files", projectId, documentCategoryId],
    async () => {
      const { data } = await axios.get<FileMetaData[]>(
        `/api/studysetup/${projectId}/getfilelist/${documentCategoryId}`
      );
      return data;
    },
    {
      onError: handleError<unknown>
    }
  );
};

export const useGetDocumentCategories = (projectId: string) => {
  return useQuery(["documentcategories", projectId], async () => {
    const { data: documentCategories } = await axios.get<
      DocumentCategoriesInfo[]
    >(`/api/projects/${projectId}/documentcategories`);
    return documentCategories;
  });
};
