import SaveIcon from "@mui/icons-material/Save";
import LoadingButton from "@mui/lab/LoadingButton";
import { Box, Button, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import Modal from "@mui/material/Modal";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  boxShadow: 24,
  maxHeight: "90vh",
  overflowY: "auto"
};

type Props = {
  title: string;
  isOpen: boolean;
  handleClose: () => void;
  onSubmit: () => void;
  isLoading: boolean;
  children: JSX.Element;
  isInAccordion?: boolean;
};

const ModalForm = ({
  title,
  isOpen,
  handleClose,
  onSubmit,
  isLoading,
  isInAccordion,
  children
}: Props) => {
  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      onClick={(event) => isInAccordion && event.stopPropagation()}
    >
      <Box sx={style}>
        <Typography
          sx={{
            textAlign: "center",
            padding: 3,
            borderBottom: `1px solid ${grey[300]}`
          }}
          variant="h5"
        >
          {title}
        </Typography>

        <form onSubmit={onSubmit}>
          <Box sx={{ padding: 3 }}>{children}</Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              padding: "24px",
              borderTop: `1px solid ${grey[300]}`
            }}
          >
            <Button
              variant="outlined"
              sx={{ marginRight: "1rem" }}
              onClick={(event) => {
                event.stopPropagation();
                handleClose();
              }}
            >
              Cancel
            </Button>
            <LoadingButton
              type="submit"
              variant="contained"
              loading={isLoading}
              loadingPosition="start"
              startIcon={<SaveIcon color="inherit" />}
            >
              Save
            </LoadingButton>
          </Box>
        </form>
      </Box>
    </Modal>
  );
};

export default ModalForm;
