import {
  AccordionDetails,
  Grid,
  Link as MuiLink,
  ListItem,
  Typography
} from "@mui/material";
import { grey } from "@mui/material/colors";
import { Link } from "react-router-dom";
import { ProjectSimpleDto } from "../../types";

type Props = {
  projects: ProjectSimpleDto[] | undefined;
  assetId: number;
};

const ProjectsAccordionDetailsList = ({ assetId, projects }: Props) => {
  if (projects?.length === 0) {
    return (
      <Typography sx={{ padding: "1rem" }}>
        No projects belong to this asset
      </Typography>
    );
  }

  return (
    <>
      {projects?.map((project) => (
        <AccordionDetails
          key={project.id}
          sx={{
            borderBottom: `1px solid ${grey[300]}`
          }}
        >
          <Grid container direction="column">
            <ListItem sx={{ paddingLeft: 0 }}>
              <MuiLink
                component={Link}
                to={`/assets/${assetId}/projects/${project.id}`}
              >
                {project.name}
              </MuiLink>
            </ListItem>
          </Grid>
        </AccordionDetails>
      ))}
    </>
  );
};

export default ProjectsAccordionDetailsList;
