import { zodResolver } from "@hookform/resolvers/zod";
import { Grid, MenuItem, TextField } from "@mui/material";
import { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { z } from "zod";
import { useAddParticipant } from "../../../api/participants";
import { useGetProjectRoles } from "api/roles";
import ModalForm from "../../../components/ModalForm";

const ParticipantSchema = z.object({
  email: z.string().email().min(1, { message: "Email is required" }),
  position: z.string().min(1, { message: "Position is required" }),
  name: z.string().min(1, { message: "Name is required" }),
  company: z.string().min(1, { message: "Company is required" }),
  roleId: z.number({ required_error: "Role is required" }),
  function: z.string().min(1, { message: "Function is required" })
});

export type ParticipantType = z.infer<typeof ParticipantSchema>;

type Props = {
  isOpen: boolean;
  handleClose: () => void;
};

type RouteProps = {
  projectId: string;
};

const AddParticipantModal = ({ isOpen, handleClose }: Props) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setError,
    reset
  } = useForm<ParticipantType>({
    resolver: zodResolver(ParticipantSchema)
  });

  const { data: roles } = useGetProjectRoles();
  const { projectId } = useParams<RouteProps>() as RouteProps;
  const { mutateAsync, isLoading, error } = useAddParticipant(projectId);

  const watchEmail = watch("email");
  const obtainableRoles = useMemo(() => {
    if (watchEmail?.includes('dnv.com')) return roles;
    return roles?.filter(role => !(role.name.includes('Scribe') || role.name.includes('Facilitator')));
  }, [watchEmail, roles])

  useEffect(() => {
    if (error) {
      setError("email", {
        type: "string",
        message: error.response!.data.email
      });
    }
  }, [error, setError]);

  return (
    <ModalForm
      title={"Add participant"}
      isOpen={isOpen}
      handleClose={handleClose}
      onSubmit={handleSubmit(async (newParticipant) => {
        await mutateAsync(newParticipant);
        reset();
        handleClose();
      })}
      isLoading={isLoading}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <TextField
            {...register("email")}
            error={!!errors.email?.message}
            helperText={errors.email?.message}
            label={"Email *"}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            {...register("position")}
            error={!!errors.position?.message}
            helperText={errors.position?.message}
            label={"Position *"}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            {...register("name")}
            error={!!errors.name?.message}
            helperText={errors.name?.message}
            label={"Name *"}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            {...register("company")}
            error={!!errors.company?.message}
            helperText={errors.company?.message}
            label={"Company *"}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            select
            {...register("roleId")}
            error={!!errors.roleId?.message}
            helperText={errors.roleId?.message}
            label={"Role *"}
            fullWidth
          >
            {obtainableRoles?.map((role) => <MenuItem value={role.id} key={role.id}>{role.name}</MenuItem>)}
          </TextField>
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            {...register("function")}
            error={!!errors.function?.message}
            helperText={errors.function?.message}
            label={"Function"}
            fullWidth
          />
        </Grid>
      </Grid>
    </ModalForm>
  );
};

export default AddParticipantModal;