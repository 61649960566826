import { zodResolver } from "@hookform/resolvers/zod";
import { Grid, TextField } from "@mui/material";
import { ChromePicker } from "react-color";
import { useController, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import {
  CustomRiskRankDto,
  useEditRiskRank
} from "../../../../../api/risk-rank";
import ModalForm from "../../../../../components/ModalForm";
import { RiskRanking, RiskRankingType } from "./AddRiskRankModal";

type Props = {
  isOpen: boolean;
  handleClose: () => void;
  riskRanking: CustomRiskRankDto;
};

type RouteParams = {
  projectId: string;
  assetId: string;
};

const EditRiskRankModal = ({
  isOpen,
  handleClose,
  riskRanking: riskRank
}: Props) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset
  } = useForm<RiskRankingType>({
    resolver: zodResolver(RiskRanking),
    defaultValues: riskRank
  });
  const { projectId } = useParams<RouteParams>() as RouteParams;
  const { mutateAsync: editRiskRank, isLoading } = useEditRiskRank(
    projectId,
    riskRank.id
  );
  const { field } = useController({
    name: "color",
    control,
    defaultValue: "#FFF"
  });

  return (
    <ModalForm
      title={"Edit custom risk rank"}
      isOpen={isOpen}
      handleClose={handleClose}
      onSubmit={handleSubmit(async (newRiskRank) => {
        await editRiskRank(newRiskRank);
        reset();
        handleClose();
      })}
      isLoading={isLoading}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            {...register("index", {
              valueAsNumber: true
            })}
            inputMode="numeric"
            error={!!errors.index?.message}
            helperText={errors.index?.message}
            label={`Index *`}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            {...register("name")}
            error={!!errors.name?.message}
            helperText={errors.name?.message}
            label={`Name *`}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            {...register("description")}
            error={!!errors.description?.message}
            helperText={errors.description?.message}
            label={`Description *`}
            fullWidth
          />
        </Grid>
        <Grid item xs={7}>
          <TextField
            {...register("color")}
            value={field.value}
            onChange={field.onChange}
            error={!!errors.color?.message}
            helperText={errors.color?.message}
            label={`Color *`}
            fullWidth
          />
        </Grid>
        <Grid item xs={5}>
          <ChromePicker
            {...field}
            onChange={(newColor) => {
              field.onChange(newColor.hex);
            }}
            color={field.value}
          />
        </Grid>
      </Grid>
    </ModalForm>
  );
};

export default EditRiskRankModal;
