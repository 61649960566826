import axios from "axios";
import { handleError } from "helpers/handleError";
import {
  NewRoleDto,
  UserAddDto,
  ViewedUser,
  UserInProjectModel
} from "../types";
import { toast } from "react-toastify";
import { useMutation, useQuery, useQueryClient } from "react-query";

export const useGetProfile = () => {
  return useQuery(
    "me",
    async () => {
      const { data } = await axios.get<ViewedUser>(`/api/users/me`);
      return data;
    },
    {
      onError: handleError<unknown>
    }
  );
};

export const useGetProjectProfile = (projectId: number) => {
  return useQuery(
    ["me-in-project", projectId],
    async () => {
      const { data } = await axios.get<UserInProjectModel>(
        `/api/users/me/project/${projectId}`
      );
      return data;
    },
    {
      onError: handleError<unknown>
    }
  );
};

export const useGetDnvUsersEmails = (email: string) => {
  return useQuery(
    ["dnvusers", email],
    async () => {
      if (email.length === 0) {
        return [];
      }
      const { data } = await axios.get<string[]>(
        `/api/users/emailquery/${email}`
      );
      return data;
    },
    { enabled: true }
  );
};

export const useGetOrganizationUsers = (organizationId: string) => {
  return useQuery(
    ["users", organizationId],
    async () => {
      const { data: users } = await axios.get<ViewedUser[]>(
        `/api/organizations/${organizationId}/users`
      );
      return users;
    },
    {
      onError: handleError<unknown>
    }
  );
};

export const useGetUsers = () => {
  return useQuery(
    ["users"],
    async () => {
      const { data: users } = await axios.get<ViewedUser[]>(`/api/users`);
      return users;
    },
    {
      onError: handleError<unknown>
    }
  );
};

export const patchUser = async (userId: number, newRoleDto: NewRoleDto) => {
  // TODO: Change response once backend is done
  const { data: isSuccess } = await axios.patch<ViewedUser>(
    `/api/users/${userId}`,
    newRoleDto
  );
  return isSuccess;
};

export const usePatchUser = (userId: number) => {
  const queryClient = useQueryClient();

  return useMutation(
    (newRoleDto: NewRoleDto) => patchUser(userId, newRoleDto),
    {
      onError: handleError<unknown>,
      onSuccess: () => {
        toast.success("Successfully edited user");
        queryClient.invalidateQueries(["users"]);
      }
    }
  );
};

export const useAddUser = (organizationId: string) => {
  const queryClient = useQueryClient();

  return useMutation(
    (newUser: UserAddDto) =>
      axios.post(`/api/organizations/${organizationId}/users`, newUser),
    {
      onError: () => {
        toast.error("Something went wrong.");
      },
      onSuccess: () => {
        toast.success("Successfully added new user to organization.");
        queryClient.invalidateQueries(["users", organizationId]);
      }
    }
  );
};

export const useInviteUser = () => {
  return useMutation((email: string) =>
    axios.post(`/api/users/invite/${email}`)
  );
};

export const useDeleteUserFromOrganization = (organizationId: string) => {
  const queryClient = useQueryClient();

  return useMutation(
    async (userId: number) => {
      const { data: isSuccess } = await axios.delete(
        `/api/organizations/${organizationId}/users/${userId}`
      );
      return isSuccess;
    },
    {
      onError: () => {
        toast.error("Something went wrong.");
      },
      onSuccess: () => {
        toast.success("Successfully removed user from organization.");
        queryClient.invalidateQueries(["users", organizationId]);
      }
    }
  );
};

export const useDeleteUser = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (userId: number) => {
      const { data: isSuccess } = await axios.delete(
        `/api/users/${userId}`
      );
      return isSuccess;
    },
    {
      onError: () => {
        toast.error("Something went wrong.");
      },
      onSuccess: () => {
        toast.success("Successfully removed user.");
        queryClient.invalidateQueries(["users"]);
      }
    }
  );
};
