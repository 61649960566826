import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionSummary,
  Box,
  Divider,
  Typography
} from "@mui/material";
import { AssetSimpleDto } from "../../types";
import AssetsActionMenu from "./AssetsActionMenu";
import ProjectsAccordionDetailsList from "./ProjectsAccordionDetailsList";

type Props = {
  assets: AssetSimpleDto[] | undefined;
  companyId: number;
};

const AssetsAccordionList = ({ assets, companyId }: Props) => {
  if (assets?.length === 0) {
    return (
      <Typography sx={{ padding: "1rem" }}>
        No assets belong to this company
      </Typography>
    );
  }

  return (
    <>
      {assets?.map((asset) => (
        <Accordion key={asset.id}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            sx={{
              width: "100%"
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%"
              }}
            >
              <Typography>{asset.assetName}</Typography>
              <AssetsActionMenu asset={asset} companyId={companyId} />
            </Box>
          </AccordionSummary>
          <Divider />
          <ProjectsAccordionDetailsList
            assetId={asset.id}
            projects={asset.projects}
          />
        </Accordion>
      ))}
    </>
  );
};

export default AssetsAccordionList;
