import { AxiosError } from "axios";
import { toast } from "react-toastify";

export const BAD_REQUEST = 400;
export const UNAUTHORIZED = 401;
export const FORBIDDEN = 403;
export const NOT_FOUND = 404;
export const METHOD_NOT_ALLOWED = 405;
export const CONFLICT = 409;

const IS_DEV = process.env.NODE_ENV === "development";

const handleDev = (error: AxiosError) => {
  switch (error.response?.status) {
    case UNAUTHORIZED:
      toast.error(`Unauthorized.`);
      break;
    case FORBIDDEN:
      toast.error(`You have no access.`);
      break;
    case METHOD_NOT_ALLOWED:
    case CONFLICT:
      toast.error(
        `Error: ${error.response.status} ${error.response.statusText}`
      );
      break;
    default:
      toast.error("Something went wrong. Please try again later.");
      break;
  }
};

export const handleError = <T = unknown>(error: AxiosError<T>) => {
  // export const handleError = (error: AxiosError<unknown>) => {
  if (IS_DEV) {
    handleDev(error);
  } else {
    switch (error.response?.status) {
      case UNAUTHORIZED:
        toast.error(`Unauthorized.`);
        break;
      case FORBIDDEN:
          toast.error(`You have no access.`);
          break;
      case CONFLICT:
        break;
      default:
        toast.error("Something went wrong. Please try again later.");
        break;
    }
  }
};
