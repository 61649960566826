import { zodResolver } from "@hookform/resolvers/zod";
import { Grid, TextField } from "@mui/material";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { useUpdateAsset } from "../../api/assets";
import ModalForm from "../../components/ModalForm";
import { AssetSimpleDto } from "../../types";

const AssetSchema = z.object({
  name: z.string().min(1, "Asset name is required").max(256),
  longitude: z
    .number()
    .min(-180, "Please use value between -180 and 180")
    .max(180, "Please use value between -180 and 180")
    .optional(),
  latitude: z
    .number()
    .min(-90, "Please use value between -90 and 90")
    .max(90, "Please use value between -90 and 90")
    .optional()
});

type EditedAsset = z.infer<typeof AssetSchema>;

type Props = {
  isOpen: boolean;
  handleClose: () => void;
  asset: AssetSimpleDto;
  companyId: number;
};

const EditAssetModal = ({ isOpen, handleClose, asset, companyId }: Props) => {
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors }
  } = useForm<EditedAsset>({
    resolver: zodResolver(AssetSchema),
    defaultValues: {
      name: asset.assetName ?? "",
      longitude: asset.longitude ?? undefined,
      latitude: asset.latitude ?? undefined
    }
  });
  const { mutateAsync, isLoading, error } = useUpdateAsset(companyId, asset.id);

  useEffect(() => {
    if (error) {
      setError("name", {
        type: "string",
        message: error.response!.data.name
      });
    }
  }, [error, setError]);

  return (
    <ModalForm
      title={"Edit Asset"}
      isOpen={isOpen}
      handleClose={handleClose}
      onSubmit={handleSubmit(async (newAsset) => {
        await mutateAsync(newAsset);
        handleClose();
      })}
      isLoading={isLoading}
      isInAccordion
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            {...register("name")}
            error={!!errors.name?.message}
            helperText={errors.name?.message}
            label={`Asset name *`}
            fullWidth
            onFocus={(e) => e.stopPropagation()}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            type="number"
            inputProps={{ step: "any" }}
            {...register("longitude", {
              setValueAs: (v) => (v === "" ? undefined : parseFloat(v))
            })}
            error={!!errors.longitude?.message}
            helperText={errors.longitude?.message}
            label={`Longitude`}
            fullWidth
            onFocus={(e) => e.stopPropagation()}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            type="number"
            inputProps={{ step: "any" }}
            {...register("latitude", {
              setValueAs: (v) => (v === "" ? undefined : parseFloat(v))
            })}
            error={!!errors.latitude?.message}
            helperText={errors.latitude?.message}
            label={`Latitude`}
            fullWidth
            onFocus={(e) => e.stopPropagation()}
          />
        </Grid>
      </Grid>
    </ModalForm>
  );
};

export default EditAssetModal;
