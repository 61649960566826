import { zodResolver } from "@hookform/resolvers/zod";
import { Autocomplete, Grid, TextField, Typography } from "@mui/material";
import { useGetUploadedFiles } from "api/document-categories";
import { useEditNode } from "api/node-analysis";
import ModalForm from "components/ModalForm";
import { Controller, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { NodeDto } from "types";
import { z } from "zod";
import { PID_ID } from "../AddNodeModal";

const NodeSchema = z.object({
  nodeIdentifier: z.string(),
  name: z.string().min(1, "Node name is required").max(256),
  designIntention: z.string().min(1, "Design intention is required"),
  operatingConditions: z
    .string()
    .min(1, "Operating Conditions is required"),
  comments: z.string().optional(),
  drawings: z
    .object({
      id: z.number(),
      name: z.string()
    })
    .array()
    .min(1, "At least 1 drawings needs to be selected")
});

type NewNode = z.infer<typeof NodeSchema>;

type Props = {
  isOpen: boolean;
  handleClose: () => void;
  node: NodeDto;
};

type RouteParams = {
  projectId: string;
};

const EditNodeModal = ({
  isOpen,
  handleClose,
  node: { files, ...rest }
}: Props) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control
  } = useForm<NewNode>({
    resolver: zodResolver(NodeSchema),
    defaultValues: {
      ...rest,
      drawings: files.map((file) => ({ id: file.id, name: file.fileName }))
    }
  });
  const { projectId } = useParams<RouteParams>() as RouteParams;

  const { mutateAsync, isLoading } = useEditNode(projectId, rest.id);
  const { data: drawings } = useGetUploadedFiles(projectId, PID_ID);

  if (!drawings) {
    return <Typography>No drawings</Typography>;
  }

  return (
    <ModalForm
      title={"Edit node"}
      isOpen={isOpen}
      handleClose={handleClose}
      onSubmit={handleSubmit(async (newNode) => {
        const { drawings, ...newNodeRest } = newNode;
        const fileIds = newNode.drawings.map((drawing) => drawing.id);
        await mutateAsync({
          ...newNodeRest,
          fileIds
        });
        reset();
        handleClose();
      })}
      isLoading={isLoading}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            {...register("nodeIdentifier")}
            error={!!errors.nodeIdentifier?.message}
            helperText={errors.nodeIdentifier?.message}
            label={`Node ID`}
            defaultValue={"Node-01"}
            disabled
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            {...register("name")}
            error={!!errors.name?.message}
            helperText={errors.name?.message}
            label={`Node name *`}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            {...register("designIntention")}
            error={!!errors.designIntention?.message}
            helperText={errors.designIntention?.message}
            label={`Design intention`}
            fullWidth
            multiline
            minRows={3}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            {...register("operatingConditions")}
            error={!!errors.operatingConditions?.message}
            helperText={errors.operatingConditions?.message}
            label={`Operating conditions`}
            fullWidth
            multiline
            minRows={3}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            control={control}
            name="drawings"
            render={({
              field: { onChange, onBlur, value, name, ref },
              fieldState: { invalid, isTouched, isDirty, error },
              formState
            }) => {
              return (
                <Autocomplete
                  multiple
                  onChange={(event, newDrawings) => {
                    onChange(newDrawings);
                  }}
                  value={value}
                  sx={{ marginBottom: "0.5rem" }}
                  options={drawings.map((d) => ({
                    id: d.id,
                    name: d.fileName
                  }))}
                  getOptionLabel={(option) => {
                    return option.name;
                  }}
                  isOptionEqualToValue={(option, value) => {
                    return option.id === value.id;
                  }}
                  renderInput={(params) => {
                    return (
                      <TextField
                        {...params}
                        label="Drawings"
                        error={!!errors.drawings}
                        helperText={errors.drawings?.message}
                      />
                    );
                  }}
                />
              );
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            {...register("comments")}
            error={!!errors.comments?.message}
            helperText={errors.comments?.message}
            label={`Comment`}
            fullWidth
          />
        </Grid>
      </Grid>
    </ModalForm>
  );
};

export default EditNodeModal;
