import { AxiosError } from "axios";
import { QueryCache, QueryClient } from "react-query";
import { toast } from "react-toastify";

const UNAUTHORIZED = 401;

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
      staleTime: 30000
    }
  },
  queryCache: new QueryCache({
    onError: (error) => {
      if ((error as AxiosError).response?.status === UNAUTHORIZED) {
        toast.error("You need to login");
      }
      // else {
      //   toast.error(`Something went wrong: ${(error as Error).message}`);
      // }
    }
  })
});
