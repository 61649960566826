import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionSummary,
  Box,
  Divider,
  Typography
} from "@mui/material";
import React from "react";
import { CompanySimpleDto } from "../../types";
import AssetsAccordionList from "./AssetsAccordionList";
import OrganizationActionMenu from "./OrganizationActionMenu";

type Props = {
  organizations: CompanySimpleDto[];
};

const OrganizationsList = ({ organizations }: Props) => {
  return (
    <>
      {organizations.map((organization) => (
        <Accordion key={organization.id} data-testid="organization-item">
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%"
              }}
            >
              <Typography variant="h4">{organization.name}</Typography>
              <OrganizationActionMenu organization={organization} />
            </Box>
          </AccordionSummary>
          <Divider />
          <AssetsAccordionList
            assets={organization.assets}
            companyId={organization.id}
          />
        </Accordion>
      ))}
    </>
  );
};

export default React.memo(OrganizationsList);
