import { fabric } from "fabric";

export interface LineCircleParams extends fabric.ICircleOptions {
  connectedLineNames: string[];
}

export class LineCircle extends fabric.Circle {
  connectedLineNames: string[];

  constructor(params: LineCircleParams) {
    super(params);
    this.connectedLineNames = params.connectedLineNames;
  }
}
