import { NewLineCoordsItem } from "api/pid";
import { fabric } from "fabric";
import Circles, { SymbolsCoords } from "./Circle/Circles";
import { LineCircle } from "./Circle/LineCircle";
import Lines, { LineCoords } from "./Lines";
import Rectangles, {
  RECTANGLE_HEIGHT,
  RECTANGLE_WIDTH,
  TextCoords
} from "./Rectangle/Rectangles";
import { Action } from "./action.enum";
import { AdePredictionItem } from "api/adepredictions";
import Predictions from "./Predictions";

class Canvas {
  isDragging: boolean;
  selection: boolean;
  lastPosX: number;
  lastPosY: number;
  canvas: fabric.Canvas;
  showPredicions: boolean = true;

  // Decides whether user is drawing lines or something else
  action: Action;
  changeAction: (newAction: Action) => void;
  saveLineCoordsToDatabase: (newLineCoords: NewLineCoordsItem) => void;

  lines: Lines;
  // You can draw lines only from an active circle
  activeCircle: LineCircle | null;
  isInPreviewMode: boolean;

  circles: Circles;
  rectangles: Rectangles;
  predictions: Predictions;

  saveSymbolCoordsToDatabase: (
    newLineCoords: any,
    element: fabric.Circle | fabric.Rect
  ) => void;
  changeSymbolCoordsInDatabase: (
    elementId: number,
    element: { x: number; y: number; width?: number; height?: number }
  ) => void;
  deleteSymbolCoordsFromDatabase: (elementId: number) => void;

  constructor(
    canvas: fabric.Canvas,
    symbolsCoords: SymbolsCoords[],
    textCoords: TextCoords[],
    changeAction: (newAction: Action) => void,
    addLineCoordsFromCanvas: (newLineCoords: NewLineCoordsItem) => void,
    saveSymbolCoords: (
      newLineCoords: any,
      element: fabric.Circle | fabric.Rect
    ) => void,
    changeSymbolCoords: (
      elementId: number,
      element: { x: number; y: number; width?: number; height?: number }
    ) => void,
    deleteSymbolsCords: (elementId: number) => void,
    preview: boolean = false,
    
  ) {
    this.isDragging = false;
    this.selection = false;
    this.lastPosX = 0;
    this.lastPosY = 0;
    this.canvas = canvas;

    this.action = Action.Nothing;
    this.changeAction = changeAction;
    this.saveLineCoordsToDatabase = addLineCoordsFromCanvas;

    this.circles = new Circles(canvas, symbolsCoords);
    this.rectangles = new Rectangles(canvas, textCoords);
    this.saveSymbolCoordsToDatabase = saveSymbolCoords;
    this.changeSymbolCoordsInDatabase = changeSymbolCoords;
    this.deleteSymbolCoordsFromDatabase = deleteSymbolsCords;

    this.activeCircle = null;
    this.isInPreviewMode = preview;

    this.predictions = new Predictions(canvas);

    this.lines = new Lines(canvas, this.circles, this.rectangles, this.predictions);
  }

  setActiveCircle(newActiveCircle: LineCircle | null) {
    this.activeCircle = newActiveCircle;
  }

  mouseWheel(canvas: fabric.Canvas) {
    canvas.on("mouse:wheel", function (opt) {
      if (opt.e.altKey) {
        var delta = opt.e.deltaY;
        var zoom = canvas.getZoom();
        zoom *= 0.999 ** delta;
        if (zoom > 20) zoom = 20;
        if (zoom < 0.01) zoom = 0.01;
        canvas.zoomToPoint({ x: opt.e.offsetX, y: opt.e.offsetY }, zoom);
        opt.e.preventDefault();
        opt.e.stopPropagation();
      }
    });
  }

  loadLinesFromDatabase(
    lineCoords: LineCoords[],
    symbolsCoords: any[] = [],
    textCoords: any[] = []
  ) {
    this.circles = new Circles(this.canvas, symbolsCoords);
    this.rectangles = new Rectangles(this.canvas, textCoords);
    this.lines.addSymbols(this.circles, this.rectangles);

    lineCoords.forEach((x) => {
      this.lines.addLineCoords(x);
      this.lines.drawLine(this.isInPreviewMode);
      this.predictions.onLineAdded(x);

      const dotes = this.lines.drawLineCircle(x.x2, x.y2, 20, "c2");
      if (this.isInPreviewMode === false)
        dotes.on("mousedown", (event) => {
          this.activeCircle = event.target as LineCircle;
          this.canvas.getObjects("line-circle").map((x) => {
            x.setOptions({ fill: "red", stroke: "red" });
            return x;
          });
          this.activeCircle.setOptions({ fill: "blue", stroke: "blue" });
          this.changeAction(Action.MarkupNode);
        });
    });
  }

  loadAdePredictions(
    predictions: AdePredictionItem[]
  ) {
    this.predictions.items = predictions;
    if (this.lines.lineCoords)
      this.lines.lineCoords.forEach(line => this.predictions.onLineAdded(line));
  }

  markupNode(x: number, y: number, isLoadingData = false) {
    // Starting new line
    if (this.activeCircle === null) {
      this.activeCircle = this.lines.drawLineCircle(x, y, 20, "c1");
      // Continue drawing line from active circle
    } else {
      const centerPoint = this.activeCircle.getCenterPoint();
      const x1 = Math.floor(centerPoint.x);
      const y1 = Math.floor(centerPoint.y);
      const x2 = Math.floor(x);
      const y2 = Math.floor(y);
      this.lines.addLineCoords({ x1, x2, y1, y2 });
      this.lines.drawLine();
      this.activeCircle = this.lines.drawLineCircle(x, y, 20, "c2");

      this.saveLineCoordsToDatabase({ x1, x2, y1, y2 });
    }

    // Set active circle on clicking it
    this.activeCircle.on("mousedown", (event) => {
      this.activeCircle = event.target as LineCircle;
      this.canvas.getObjects("line-circle").map((x) => {
        x.setOptions({ fill: "red", stroke: "red" });
        return x;
      });
      this.activeCircle.setOptions({ fill: "blue", stroke: "blue" });
      this.changeAction(Action.MarkupNode);
    });
  }

  isObjectClicked() {
    const activeObject = this.canvas.getActiveObject();
    return Boolean(activeObject);
  }

  mouseDown(canvas: fabric.Canvas) {
    canvas.on("mouse:down", (opt) => {
      const evt = opt.e;
      const { x, y } = this.getMouse(opt);

      if (this.isObjectClicked()) {
        return;
      }

      if (evt.altKey === true) {
        this.isDragging = true;
        this.selection = false;
        this.lastPosX = evt.clientX;
        this.lastPosY = evt.clientY;
      } else {
        switch (this.action) {
          case Action.StartNewLine: {
            this.activeCircle = null;
            this.changeAction(Action.MarkupNode);
            // This will draw first dot of a line
            this.markupNode(x, y);
            break;
          }
          case Action.MarkupNode: {
            this.markupNode(x, y);
            break;
          }
          case Action.AddSymbol: {
            const { x, y } = this.getMouse(opt);
            console.log("AddSymbol", { x, y });
            this.saveSymbolCoordsToDatabase(
              {
                x: Math.round(x),
                y: Math.round(y),
                type: 1
              },
              this.circles.drawCircle(Math.round(x), Math.round(y), 100, false)
            );

            break;
          }
          case Action.SelectText: {
            const { x, y } = this.getMouse(opt);
            console.log("SelectText", { x, y });
            this.saveSymbolCoordsToDatabase(
              {
                x: Math.round(x) - RECTANGLE_WIDTH / 2,
                y: Math.round(y) - RECTANGLE_HEIGHT / 2,
                type: 2,
                width: RECTANGLE_WIDTH,
                height: RECTANGLE_HEIGHT
              },
              this.rectangles.drawRectangle(
                x - RECTANGLE_WIDTH / 2,
                y - RECTANGLE_HEIGHT / 2,
                RECTANGLE_WIDTH,
                RECTANGLE_HEIGHT,
                "red",
                false
              )
            );
          }
        }
      }
    });
  }

  mouseMove(canvas: fabric.Canvas) {
    canvas.on("mouse:move", (opt) => {
      if (this.isDragging) {
        console.log(this);
        var e = opt.e;
        var vpt = canvas.viewportTransform as number[];
        vpt[4] += e.clientX - this.lastPosX;
        vpt[5] += e.clientY - this.lastPosY;
        canvas.requestRenderAll();
        this.lastPosX = e.clientX;
        this.lastPosY = e.clientY;
      }
    });
  }

  mouseUp(canvas: fabric.Canvas) {
    canvas.on("mouse:up", (opt) => {
      // on mouse up we want to recalculate new interaction
      // for all objects, so we call setViewportTransform
      canvas.setViewportTransform(canvas.viewportTransform as number[]);
      this.isDragging = false;
      this.selection = true;
      //
      const nameArr = opt.target?.name?.split("_");

      if (
        nameArr?.length === 2 &&
        opt.target &&
        opt.target.aCoords &&
        parseInt(nameArr[1])
      ) {
        const { x, y } = this.getMouse(opt);

        const coordsTlx: number = opt.target.aCoords?.tl.x || 0;
        const coordsTly: number = opt.target.aCoords?.tl.y || 0;
        const coordsTrx: number = opt.target.aCoords?.tr.x || 0;
        const coordsBly: number = opt.target.aCoords?.bl.y || 0;

        const _width: number = Math.round(coordsTrx - coordsTlx);
        const _height: number = Math.round(coordsBly - coordsTly);

        if (nameArr[0] === "circle") {
          this.changeSymbolCoordsInDatabase(parseInt(nameArr[1]), {
            x: Math.round(coordsTlx + _width / 2) - 2,
            y: Math.round(coordsTly + _height / 2) - 2
          });
        } else {
          this.changeSymbolCoordsInDatabase(parseInt(nameArr[1]), {
            x: Math.round((coordsTlx || x) + _width / 2),
            y: Math.round((coordsTly || y) + _height / 2),
            width: _width - 10,
            height: _height - 10
          });
        }
      }
    });
  }

  getMouse(options: fabric.IEvent<MouseEvent>) {
    var pointer = this.canvas.getPointer(options.e);
    var posx = pointer.x;
    var posy = pointer.y;
    return { x: posx, y: posy };
  }

  objectMoving(canvas: fabric.Canvas) {
    canvas.on("object:moving", (options) => {
      // const objType = options.target!.get("type");
      // if (objType === "circle") {
      //   console.log(options);
      // }
    });
  }

  keyboardClick() {
    window.addEventListener("keydown", (event) => {
      // console.log(event.key);
      switch (event.key) {
        case "Delete": {
          const activeObject = this.canvas.getActiveObject();

          if (activeObject && activeObject.type !== "line-circle") {
            if (activeObject?.name) {
              const nameArr = activeObject?.name.split("_");
              const objectId = parseInt(nameArr[1]);

              if (objectId > 0) this.deleteSymbolCoordsFromDatabase(objectId);
              this.canvas.remove(activeObject);
            }
          }
        }
      }
    });
  }

  initEventListeners(canvas: fabric.Canvas) {
    this.mouseWheel(canvas);
    this.mouseDown(canvas);
    this.mouseMove(canvas);
    this.mouseUp(canvas);
    this.objectMoving(canvas);
    this.keyboardClick();
  }
}

export default Canvas;
