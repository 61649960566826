import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { Box, Button, Grid, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { useTheme } from "@mui/material/styles";
import { useCallback, useState } from "react";
import { DropzoneOptions, useDropzone } from "react-dropzone";
import FileWithIcon from "./FileWithIcon";

type Props = {
  onUpload: (files: File[]) => Promise<void>;
};

type FilesToBeUploadedProps = {
  files: File[];
};
const FilesToBeUploadedList = ({ files }: FilesToBeUploadedProps) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        marginTop: theme.spacing(2)
      }}
    >
      <Typography variant="h6" sx={{ marginBottom: theme.spacing(2) }}>
        Files to be uploaded:
      </Typography>
      <Grid container spacing={1}>
        {files.map((file, index) => (
          <Grid item xs={3} md={2} lg={1} key={index}>
            <FileWithIcon fileName={file.name} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

function FileUpload({ onUpload }: Props) {
  const theme = useTheme();
  const [files, setFiles] = useState<File[]>([]);

  const onDrop = useCallback((acceptedFiles: File[]) => {
    setFiles((prevFiles) => [...prevFiles, ...acceptedFiles]);
  }, []);

  const dropzoneOptions: DropzoneOptions = {
    accept: { "application/pdf": [".pdf"] },
    multiple: true,
    maxSize: 5000000
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    ...dropzoneOptions,
    onDrop
  });

  return (
    <form
      onSubmit={(event) => {
        event.preventDefault();
        onUpload(files);
        setFiles([]);
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          minHeight: 200,
          backgroundColor: theme.palette.background.paper,
          padding: theme.spacing(2),
          borderRadius: theme.shape.borderRadius,
          border: `2px dashed ${
            isDragActive ? theme.palette.primary.light : grey[500]
          }`,
          color: grey[500],
          cursor: "pointer"
        }}
        {...getRootProps()}
      >
        <input {...getInputProps()} />
        <CloudUploadIcon
          style={{
            fontSize: "5rem",
            marginBottom: theme.spacing(2),
            color: grey[500]
          }}
        />
        <Typography variant="body1" align="center">
          {isDragActive
            ? "Drop the files here..."
            : "Drag and drop files here, or click to select files"}
        </Typography>
      </Box>
      {files.length > 0 && <FilesToBeUploadedList files={files} />}

      <Button
        type="submit"
        variant="contained"
        sx={{ marginTop: "1rem" }}
        disabled={files.length === 0}
      >
        Submit
      </Button>
    </form>
  );
}

export default FileUpload;
