import DescriptionIcon from "@mui/icons-material/Description";
import { Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import ProjectDetailLinkItem from "./ProjectDetailLinkItem";

const hazopSessionItems = [
  {
    title: "HAZOP Worksheet",
    text: "Add and manage workshop participants",
    icon: <DescriptionIcon />,
    to: "worksheet"
  }
];

const HazopSession = () => (
  <>
    <Typography variant={"h4"} mt={5} mb={1}>
      Hazop Session
    </Typography>
    <Grid container spacing={2}>
      {hazopSessionItems.map((item) => (
        <ProjectDetailLinkItem {...item} key={item.to} />
      ))}
    </Grid>
  </>
);

export default HazopSession;
