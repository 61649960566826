import axios from "axios";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { AddCustomLikelihoodDto, EditLikelihoodDto, Likelihood } from "types";
import { handleError } from "../helpers/handleError";

export const useGetSelectedMatrixLikelihoods = (projectId: string) => {
  return useQuery(["matrix-likelihoods", projectId], async () => {
    const { data } = await axios.get<Likelihood[]>(
      `/api/projects/${projectId}/matrixes/selected/likelihoods`
    );
    return data.sort((l1, l2) => l1.index - l2.index);
  },
  {
    onError: handleError<unknown>
  });
};

export const useAddCustomLikelihood = (projectId: string) => {
  const queryClient = useQueryClient();

  return useMutation(
    async (newCustomLikelihood: AddCustomLikelihoodDto) => {
      const { data: customLikelihood } = await axios.post<Likelihood>(
        `/api/projects/${projectId}/matrixes/selected/likelihoods`,
        newCustomLikelihood
      );
      return customLikelihood;
    },
    {
      onError: handleError<{ name: string }>,
      onSuccess: ({ riskMatrixId }) => {
        toast.success("Successfully added new likelihood.");
        queryClient.invalidateQueries(["matrix-likelihoods", projectId]);
        queryClient.invalidateQueries(["classifications", projectId]);
      }
    }
  );
};

export const useDeleteLikelihood = (projectId: string) => {
  const queryClient = useQueryClient();

  return useMutation(
    async (LikelihoodId: number) => {
      const { data: isSuccess } = await axios.delete<boolean>(
        `/api/projects/${projectId}/matrixes/selected/likelihoods/${LikelihoodId}`
      );
      return isSuccess;
    },
    {
      onError: handleError<unknown>,
      onSuccess: () => {
        toast.success("Successfully deleted likelihood.");
        queryClient.invalidateQueries(["matrix-likelihoods", projectId]);
        queryClient.invalidateQueries(["classifications", projectId]);
      }
    }
  );
};

export const useEditLikelihood = (projectId: string, likelihoodId: number) => {
  const queryClient = useQueryClient();

  return useMutation(
    async (updatedLikelihood: EditLikelihoodDto) => {
      const { data: isSuccess } = await axios.put<boolean>(
        `/api/projects/${projectId}/matrixes/selected/likelihoods/${likelihoodId}`,
        updatedLikelihood
      );
      return isSuccess;
    },
    {
      onError: handleError<unknown>,
      onSuccess: () => {
        toast.success("Successfully edited likelihood.");
        queryClient.invalidateQueries(["matrix-likelihoods", projectId]);
      }
    }
  );
};
