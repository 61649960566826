import { Box, Button, Typography } from "@mui/material";
import { lightBlue } from "@mui/material/colors";
import { useGetAsset } from "api/assets";
import { useGetTimeframes } from "api/timeframes";
import { useGetProfile } from "api/users";
import Loader from "components/Loader";
import { formatDate } from "helpers/commonHelpers";
import { isAuthorized, RoleEnum } from "helpers/isAuthorized";
import { useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { TimeframeDto } from "types";
import AddSessionModal from "./AddSessionModal";
import SessionDatagrid from "./SessionDatagrid";
import moment from "moment";

type Props = {};

type RouteParams = {
  assetId: string;
  projectId: string;
};

export type TimeframeWithSessionProps = {
  timeframe: TimeframeDto;
  isEditable: boolean;
};
export const TimeframeWithSession = ({
  timeframe, isEditable
}: TimeframeWithSessionProps) => {
  const [isAddSessionModalVisible, setIsAddSessionModalVisible] =
    useState(false);

  return (
    <Box sx={{ marginBottom: "4rem" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "1rem",
          background: lightBlue[100]
        }}
      >
        <Box>
          <Typography variant="h6">{timeframe.description}</Typography>
          <i>
            {formatDate(moment(timeframe.startDate).toDate())} - {formatDate(moment(timeframe.endDate).toDate())}
          </i>
        </Box>
        {isEditable && (
          <Button
            onClick={() => setIsAddSessionModalVisible(true)}
            variant="contained"
            sx={{ minWidth: "180px", maxHeight: 36 }}
          >
            Add session
          </Button>
        )}

        {isAddSessionModalVisible && (
          <AddSessionModal
            isOpen={isAddSessionModalVisible}
            handleClose={() => setIsAddSessionModalVisible(false)}
            timeframe={timeframe}
          />
        )}
      </Box>
      <SessionDatagrid timeframe={timeframe} isEditable={isEditable} />
    </Box>
  );
};

const TimeframesWithSessions = (props: Props) => {
  const { assetId, projectId } = useParams<RouteParams>() as RouteParams;
  const { data: timeframes } = useGetTimeframes(projectId);
  const { data: profile } = useGetProfile();
  const { data: asset } = useGetAsset(assetId);

  const organizationRoles: RoleEnum[] = useMemo(() => {
    if (!asset?.organizationId || !profile?.organizationRoles) return [];
    return profile?.organizationRoles?.filter((orgRoles) => orgRoles.organizationId === asset.organizationId)?.map((orgRoles) => orgRoles.roleName as RoleEnum) || []
  }, [asset, profile])

  if (!timeframes || !profile || !asset) return <Loader />;

  const isEditable = isAuthorized({
    role: profile.roleName as RoleEnum,
    organizationRoles: organizationRoles,
    requiredRoles: [RoleEnum.Administrator, RoleEnum.OrganizationOwner]
  });

  return (
    <>
      {timeframes?.map((timeframe) => (
        <TimeframeWithSession key={timeframe.id} timeframe={timeframe} isEditable={isEditable} />
      ))}
    </>
  );
};

export default TimeframesWithSessions;
