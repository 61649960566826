import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  Box,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem
} from "@mui/material";
import { useGetProfile } from "api/users";
import Loader from "components/Loader";
import { RoleEnum, isAuthorized } from "helpers/isAuthorized";
import { useState } from "react";
import { AssetSimpleDto } from "../../types";
import AddProjectModal from "./AddProjectModal";
import EditAssetModal from "./EditAssetModal";

// const ITEM_HEIGHT = 48;

type Props = {
  asset: AssetSimpleDto;
  companyId: number;
};

const AssetsActionMenu = ({ asset, companyId }: Props) => {
  const [isEditAssetModalOpen, setIsEditAssetModalOpen] = useState(false);
  const [isAddProjectModalOpen, setIsAddProjectModalOpen] = useState(false);

  const { data: profile } = useGetProfile();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: <EditIcon />,
      onClick: () => setIsEditAssetModalOpen(true),
      requiredRoles: [RoleEnum.Administrator, RoleEnum.OrganizationOwner]
    },
    {
      label: "Add project",
      icon: <AddIcon />,
      onClick: () => setIsAddProjectModalOpen(true),
      requiredRoles: [RoleEnum.Administrator, RoleEnum.OrganizationOwner]
    }
  ];

  if (!profile) return <Loader />;

  const organizationRoles: RoleEnum[] = profile.organizationRoles?.filter((orgRoles) => orgRoles.organizationId === companyId).map((orgRoles) => orgRoles.roleName as RoleEnum) || [];
  const filteredMenuOptions = MENU_OPTIONS.filter((x) =>
    isAuthorized({
      role: profile.roleName as RoleEnum,
      organizationRoles: organizationRoles,
      requiredRoles: x.requiredRoles
    })
  );

  if (filteredMenuOptions.length === 0) return null;

  return (
    <Box>
      <IconButton aria-label="more" id="long-button" onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button"
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        keepMounted
      >
        {MENU_OPTIONS.map((option) => (
          <MenuItem
            key={option.label}
            onClick={(event) => {
              event.stopPropagation();
              option.onClick();
              handleClose(event);
            }}
          >
            <ListItemIcon>{option.icon}</ListItemIcon>
            <ListItemText>{option.label}</ListItemText>
          </MenuItem>
        ))}
      </Menu>

      <Box onClick={(event) => event.stopPropagation()}>
        {isEditAssetModalOpen && (
          <EditAssetModal
            isOpen={isEditAssetModalOpen}
            handleClose={() => {
              setIsEditAssetModalOpen(false);
            }}
            asset={asset}
            companyId={companyId}
          />
        )}

        <AddProjectModal
          isOpen={isAddProjectModalOpen}
          handleClose={() => setIsAddProjectModalOpen(false)}
          assetId={asset.id}
        />
      </Box>
    </Box>
  );
};

export default AssetsActionMenu;
