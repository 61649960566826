import { zodResolver } from "@hookform/resolvers/zod";
import { Grid, InputAdornment, TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import { Controller, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { useEditTimeframe } from "../../api/timeframes";
import { defaultDateFormat } from "../../common/date";
import ModalForm from "../../components/ModalForm";
import { TimeframeDto } from "../../types";
import { TimeframeSchema, TimeframeType } from "./AddTimeframeModal";

type Props = {
  isOpen: boolean;
  handleClose: () => void;
  timeframe: TimeframeDto;
};

type RouteProps = {
  projectId: string;
};

const EditTimeframeModal = ({ isOpen, handleClose, timeframe }: Props) => {
  const {
    register, handleSubmit, getValues, setValue,
    control, reset, watch, formState: { errors }
  } = useForm<TimeframeType>({
    resolver: zodResolver(TimeframeSchema),
    defaultValues: {
      address: timeframe.address,
      description: timeframe.description,
      duration: timeframe.endDate && timeframe.startDate ? moment(timeframe.endDate).diff(timeframe.startDate, 'days') : undefined,
      // @ts-ignore
      endDate: moment(timeframe.endDate),
      // @ts-ignore
      startDate: moment(timeframe.startDate)
    }
  });
  const { projectId } = useParams<keyof RouteProps>() as RouteProps;
  const { mutateAsync } = useEditTimeframe(projectId);

  return (
    <ModalForm
      title={"Edit time frame "}
      isOpen={isOpen}
      handleClose={handleClose}
      onSubmit={handleSubmit(async (newTimeframe) => {
        const startDate = moment.utc(newTimeframe.startDate).toISOString();
        const endDate = moment.utc(newTimeframe.endDate).toISOString();
        await mutateAsync({
          timeframeId: timeframe.id,
          updatedTimeframe: {
            ...newTimeframe,
            startDate,
            endDate
          }
        });
        reset();
        handleClose();
      })}
      isLoading={false}
    >
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Controller
            name="startDate"
            control={control}
            // @ts-ignore
            defaultValue={null}
            render={({ field }) => (
              <DatePicker
                sx={{ width: "100%" }}
                onChange={(newValue) => {
                  field.onChange(moment(newValue).utc());
                  const endDateValue = getValues("endDate")
                  if (endDateValue) setValue("duration", moment(endDateValue).diff(newValue, 'days'));
                }}
                value={field.value}
                label="Timeframe start date *"
                format={defaultDateFormat}
                // Don't allow the user to pick dates later than End Date
                shouldDisableDate={(date) => {
                  const endDate = watch("endDate");
                  if (endDate) {
                    return date > endDate;
                  }
                  return false;
                }} //!!endDate && date > endDate}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <Controller
            name="endDate"
            control={control}
            // @ts-ignore
            defaultValue={null}
            render={({ field }) => (
              <DatePicker
                sx={{ width: "100%" }}
                onChange={(newValue) => {
                  field.onChange(moment(newValue).utc());
                  const startDateValue = getValues("startDate");
                  if (startDateValue) setValue("duration", moment(newValue).diff(startDateValue, 'days'));
                }}
                value={field.value}
                label="Timeframe end date *"
                format={defaultDateFormat}
                // Don't allow the user to pick dates earlier than Start Date
                shouldDisableDate={(date) => {
                  const startDate = watch("startDate") as Date;
                  return date < startDate;
                }}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            {...register("address")}
            fullWidth
            label="Address *"
            error={!!errors.address}
            helperText={errors.address?.message}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            disabled={true}
            {...register("duration", { valueAsNumber: true })}
            type="number"
            fullWidth
            label="Duration *"
            error={!!errors.duration}
            helperText={errors.duration?.message}
            InputProps={{
              endAdornment: <InputAdornment position="end">days</InputAdornment>
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            {...register("description")}
            fullWidth
            label="Description *"
            multiline
            minRows={4}
            error={!!errors.description}
            helperText={errors.description?.message}
          />
        </Grid>
      </Grid>
    </ModalForm>
  );
};

export default EditTimeframeModal;
