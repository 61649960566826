import axios from "axios";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { handleError } from "../helpers/handleError";
import {
  CompanySimpleDto,
  OrganizationDto,
  OrganizationViewModel
} from "../types";

interface OrganizationAddDto {
  companyName: string;
  accountId?: string;
  companyCode?: string;
}

export const addOrganization = async (newOrganization: OrganizationAddDto) => {
  const { data: organization } = await axios.post<OrganizationViewModel>(
    `/api/organizations`,
    newOrganization
  );
  return organization;
};

export const useAddOrganization = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (newOrganization: OrganizationAddDto) => addOrganization(newOrganization),
    {
      onError: handleError<{ name: string }>,
      onSuccess: () => {
        toast.success("Successfully added new organization.");
        queryClient.invalidateQueries("organizationsWithAssetsAndProjects");
      }
    }
  );
};

export const useGetOrganizationsWithAssetsAndProjects = () => {
  return useQuery(
    "organizationsWithAssetsAndProjects",
    async () => {
      const { data } = await axios.get<CompanySimpleDto[]>(
        `/api/projectlistdata`
      );
      return data;
    },
    {
      onError: handleError<unknown>
    }
  );
};

export const updateOrganization = async (
  organizationId: number,
  updatedOrganization: OrganizationAddDto
) => {
  const { data: organization } = await axios.put<OrganizationAddDto>(
    `/api/organizations/${organizationId}`,
    updatedOrganization
  );
  return organization;
};

export const useUpdateOrganization = (organizationId: number) => {
  const queryClient = useQueryClient();

  return useMutation(
    (updatedOrganization: OrganizationAddDto) =>
      updateOrganization(organizationId, updatedOrganization),
    {
      onError: handleError<{ name: string }>,
      onSuccess: () => {
        toast.success("Successfully updated organization.");
        queryClient.invalidateQueries("organizationsWithAssetsAndProjects");
      }
    }
  );
};

export const useGetOrganizations = () => {
  return useQuery(
    "organizations",
    async () => {
      const { data } = await axios.get<OrganizationDto[]>(`/api/organizations`);
      return data;
    },
    {
      onError: handleError<unknown>
    }
  );
};
