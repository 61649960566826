import { Typography } from "@mui/material";
import { useGetAsset } from "api/assets";
import { useGetProfile } from "api/users";
import { RoleEnum, isAuthorized } from "helpers/isAuthorized";
import { useParams } from "react-router-dom";
import {
  useGetAssetProperties,
  useGetAssetTypes
} from "../../api/asset-properties";
import Loader from "../../components/Loader";
import Title from "../../components/Title";
import AssetPropertiesForm from "./AssetPropertiesForm";
import { useMemo } from "react";

type Props = {};

type RouteParams = {
  assetId: string;
  projectId: string;
};

const AssetProperties = (props: Props) => {
  const { assetId, projectId } = useParams<RouteParams>() as RouteParams;
  const { data: assetProperties, isFetched: isFetchedAssetProperties } = useGetAssetProperties(projectId);
  const { data: assetTypes, isLoading: isLoadingAssetTypes } = useGetAssetTypes();
  const { data: asset } = useGetAsset(assetId);
  const { data: profile } = useGetProfile();

  const isLoading = isFetchedAssetProperties && isLoadingAssetTypes;

  const organizationRoles: RoleEnum[] = useMemo(() => {
    if (!asset?.organizationId || !profile?.organizationRoles) return [];
    return profile?.organizationRoles?.filter((orgRoles) => orgRoles.organizationId === asset.organizationId)?.map((orgRoles) => orgRoles.roleName as RoleEnum) || []
  }, [asset, profile])

  if (isLoading || !assetTypes || !asset || !profile) return <Loader />;

  const isEditable = isAuthorized({
    role: profile.roleName as RoleEnum,
    organizationRoles: organizationRoles,
    requiredRoles: [RoleEnum.Administrator, RoleEnum.OrganizationOwner]
  });

  return (
    <>
      <Title>
        <Typography variant="h3">Asset Properties</Typography>
      </Title>

      <AssetPropertiesForm
        assetProperties={assetProperties}
        assetTypes={assetTypes}
        projectId={projectId}
        isReadOnly={!isEditable}
      />
    </>
  );
};

export default AssetProperties;
