import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import GroupIcon from "@mui/icons-material/Group";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem
} from "@mui/material";
import { useGetProfile } from "api/users";
import Loader from "components/Loader";
import { RoleEnum, isAuthorized } from "helpers/isAuthorized";
import { useState } from "react";
import { useNavigate } from "react-router";
import { CompanySimpleDto } from "../../types";
import AddAssetModal from "./AddAssetModal";
import EditOrganizationModal from "./EditOrganizationModal";

const ITEM_HEIGHT = 48;

type Props = {
  organization: CompanySimpleDto;
};

const OrganizationActionMenu = ({ organization }: Props) => {
  const navigate = useNavigate();

  const [isEditCompanyModalOpen, setIsEditCompanyModalOpen] = useState(false);
  const [isAddAssetModalOpen, setIsAddAssetModalOpen] = useState(false);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const { data: profile } = useGetProfile();
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  if (!profile) return <Loader />;

  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: <EditIcon />,
      onClick: () => setIsEditCompanyModalOpen(true),
      requiredRoles: [RoleEnum.Administrator, RoleEnum.OrganizationOwner]
    },
    {
      label: "Add asset",
      icon: <AddIcon />,
      onClick: () => setIsAddAssetModalOpen(true),
      requiredRoles: [RoleEnum.Administrator, RoleEnum.OrganizationOwner]
    },
    {
      label: "Users",
      icon: <GroupIcon />,
      onClick: () => navigate(`/organizations/${organization.id}/users`),
      requiredRoles: [RoleEnum.Administrator, RoleEnum.OrganizationOwner, RoleEnum.OrganizationReader, RoleEnum.GlobalReader]
    }
  ];

  const organizationRoles: RoleEnum[] = profile.organizationRoles?.filter((orgRoles) => orgRoles.organizationId === organization.id).map((orgRoles) => orgRoles.roleName as RoleEnum) || [];
  const filteredMenuOptions = MENU_OPTIONS.filter((x) =>
    isAuthorized({
      role: profile.roleName as RoleEnum,
      organizationRoles: organizationRoles,
      requiredRoles: x.requiredRoles
    })
  );

  if (filteredMenuOptions.length === 0) return null;

  return (
    <>
      <IconButton aria-label="more" id="long-button" onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button"
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: "20ch"
          }
        }}
      >
        {filteredMenuOptions.map((option) => (
          <MenuItem
            key={option.label}
            onClick={(e) => {
              option.onClick();
              handleClose(e);
            }}
          >
            <ListItemIcon>{option.icon}</ListItemIcon>
            <ListItemText>{option.label}</ListItemText>
          </MenuItem>
        ))}
      </Menu>

      {isEditCompanyModalOpen && (
        <EditOrganizationModal
          isOpen={isEditCompanyModalOpen}
          handleClose={() => setIsEditCompanyModalOpen(false)}
          organization={organization}
        />
      )}

      <AddAssetModal
        isOpen={isAddAssetModalOpen}
        handleClose={() => setIsAddAssetModalOpen(false)}
        companyId={organization.id}
      />
    </>
  );
};

export default OrganizationActionMenu;
