import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableRow
} from "@mui/material";
import Loader from "components/Loader";
import { useState } from "react";
import { useParams } from "react-router-dom";
import {
  useDeleteConsequenceCategory,
  useGetSelectedMatrixConsequenceCategories
} from "../../../../../api/matrixes";
import ConfirmationDialog from "../../../../../components/ConfirmationDialog";
import TitleWithAddButton from "../../../../../components/TitleWithAddButton";
import { ConsequenceCategory } from "../../../../../types";
import AddConsequenceCategoryModal from "./AddConsequenceCategoryModal";
import EditCustomConsequenceCategoryModal from "./EditCustomConsequenceCategoryModal";

type RouteParams = {
  projectId: string;
  assetId: string;
};

type Props = {
  isReadOnly: boolean;
  isDnv?: boolean;
};

const CustomConsequenceCategories = ({ isReadOnly, isDnv }: Props) => {
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedConsequenceCategory, setSelectedConsequenceCategory] =
    useState<ConsequenceCategory>();
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] =
    useState(false);
  const { projectId } = useParams<RouteParams>() as RouteParams;

  const { mutateAsync } = useDeleteConsequenceCategory(projectId);
  const {
    data: consequenceCategories,
    isLoading: isLoadingConsequenceCategories
  } = useGetSelectedMatrixConsequenceCategories(projectId);

  if (isLoadingConsequenceCategories) return <Loader />;

  return (
    <Box sx={{ marginTop: "4rem", marginBottom: "2rem" }}>
      <TitleWithAddButton
        title={"Consequence categories"}
        modal={
          <AddConsequenceCategoryModal
            isOpen={isAddModalOpen}
            handleClose={() => setIsAddModalOpen(false)}
          />
        }
        openModal={() => setIsAddModalOpen(true)}
        buttonText={"Add consequence category"}
        titleVariant="h4"
        isButtonVisible={!isDnv && !isReadOnly}
      />

      <Table>
        <TableBody>
          {consequenceCategories?.map((consequenceCategory) => (
            <TableRow key={consequenceCategory.name}>
              <TableCell sx={{ width: "90%" }}>
                {consequenceCategory.name}
              </TableCell>
              {!isDnv && !isReadOnly && (
                <>
                  <TableCell align="right" sx={{ padding: "4px" }}>
                    <IconButton
                      onClick={() => {
                        setIsDeleteConfirmationOpen(true);
                        setSelectedConsequenceCategory(consequenceCategory);
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                  <TableCell align="right" sx={{ padding: "4px" }}>
                    <IconButton
                      onClick={() => {
                        setIsEditModalOpen(true);
                        setSelectedConsequenceCategory(consequenceCategory);
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                  </TableCell>
                </>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>

      {selectedConsequenceCategory && isEditModalOpen && (
        <EditCustomConsequenceCategoryModal
          isOpen={isEditModalOpen}
          handleClose={() => {
            setIsEditModalOpen(false);
            setSelectedConsequenceCategory(undefined);
          }}
          customConsequenceCategory={selectedConsequenceCategory}
        />
      )}

      <ConfirmationDialog
        isOpen={isDeleteConfirmationOpen}
        handleClose={() => setIsDeleteConfirmationOpen(false)}
        title={"Delete consequence category"}
        text={"Are you sure you want to delete consequence category"}
        onAgree={() => {
          mutateAsync(selectedConsequenceCategory!.id);
          setIsDeleteConfirmationOpen(false);
        }}
      />
    </Box>
  );
};

export default CustomConsequenceCategories;
