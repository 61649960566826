import InfoIcon from "@mui/icons-material/Info";
import { Box, Typography } from "@mui/material";
import { useGetAsset } from "api/assets";
import { useGetProfile } from "api/users";
import Loader from "components/Loader";
import TitleWithAddButton from "components/TitleWithAddButton";
import { isAuthorized, RoleEnum } from "helpers/isAuthorized";
import { useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import AddHazopColumnModal from "./AddHazopColumnModal";
import HazopSetupTable from "./HazopSetupTable";

type Props = {};
type RouteParams = {
  assetId: string;
  projectId: string;
};

const HazopSetup = (props: Props) => {
  const { assetId } = useParams<RouteParams>() as RouteParams;
  const [isInviteModalOpen, setIsInviteModalOpen] = useState(false);
  const { data: profile } = useGetProfile();
  const { data: asset } = useGetAsset(assetId);

  const organizationRoles: RoleEnum[] = useMemo(() => {
    if (!asset?.organizationId || !profile?.organizationRoles) return [];
    return profile?.organizationRoles?.filter((orgRoles) => orgRoles.organizationId === asset.organizationId)?.map((orgRoles) => orgRoles.roleName as RoleEnum) || []
  }, [asset, profile])

  if (!profile || !asset) {
    return <Loader />;
  }

  const isEditable = isAuthorized({
    role: profile.roleName as RoleEnum,
    organizationRoles: organizationRoles,
    requiredRoles: [RoleEnum.Administrator, RoleEnum.OrganizationOwner]
  });

  return (
    <Box>
      <Box>
        <TitleWithAddButton
          title={"HAZOP worksheet"}
          textUnderTitle={"Define the contents of the HAZOP worksheet"}
          modal={
            <AddHazopColumnModal
              isOpen={isInviteModalOpen}
              handleClose={() => setIsInviteModalOpen(false)}
            />
          }
          openModal={() => setIsInviteModalOpen(true)}
          buttonText={"Add column"}
          buttonSx={{ position: "absolute", right: "25px", padding: "1rem" }}
          isButtonVisible={isEditable}
        />
      </Box>

      {isEditable && (
        <Box sx={{ display: "flex", marginBottom: "0.5rem" }}>
          <InfoIcon color={"primary"} />
          <Typography sx={{ marginLeft: "0.5rem" }}>
            Click to select the additional columns for the HAZOP worksheet.
          </Typography>
        </Box>
      )}

      <Box sx={{ marginLeft: "2rem", marginBottom: "2rem" }}>
        <Typography>S - Severity level</Typography>
        <Typography>L - Likelihood level</Typography>
        <Typography>RR - Risk ranking</Typography>
      </Box>

      <HazopSetupTable isEditable={isEditable} />
    </Box>
  );
};

export default HazopSetup;
