import axios from "axios";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { handleError } from "../helpers/handleError";
import { AssetAddDto as AssetEditDto, AssetViewModel } from "../types";

export const useGetAsset = (assetId: string) => {
  return useQuery(
    ["asset", assetId],
    async () => {
      const { data } = await axios.get(`/api/assets/${assetId}`);
      return data;
    },
    {
      onError: handleError<unknown>
    }
  );
};

export const addAsset = async (companyId: number, newAsset: AssetEditDto) => {
  const { data } = await axios.post<AssetViewModel>(
    `/api/organizations/${companyId}/asset`,
    newAsset
  );
  return data;
};

export const useAddAsset = (companyId: number) => {
  const queryClient = useQueryClient();

  return useMutation(
    (newAsset: AssetEditDto) => addAsset(companyId, newAsset),
    {
      onError: handleError<{ name: string }>,
      onSuccess: () => {
        toast.success("Successfully added new asset.");
        queryClient.invalidateQueries("organizationsWithAssetsAndProjects");
      }
    }
  );
};

export const updateAsset = async (
  companyId: number,
  assetId: number,
  updatedAsset: AssetEditDto
) => {
  const { data: asset } = await axios.put<AssetViewModel>(
    `/api/organizations/${companyId}/assets/${assetId}`,
    updatedAsset
  );
  return asset;
};

export const useUpdateAsset = (companyId: number, assetId: number) => {
  const queryClient = useQueryClient();

  return useMutation(
    (updatedCompany: AssetEditDto) =>
      updateAsset(companyId, assetId, updatedCompany),
    {
      onError: handleError<{ name: string }>,
      onSuccess: () => {
        toast.success("Successfully updated asset.");
        queryClient.invalidateQueries("organizationsWithAssetsAndProjects");
      }
    }
  );
};
