import { fabric } from "fabric";
import { LineCoords } from "../Lines";

export interface SymbolsCoords {
  id: number;
  x: number;
  y: number;
}

export default class Circles {
  constructor(
    private canvas: fabric.Canvas,
    private symbolsCoords: SymbolsCoords[]
  ) { }

  drawCircle(x: number, y: number, radius: number, lockMovement = false, id = -1) {
    const circle = new fabric.Circle({
      name: `circle_${id}`,
      radius: radius,
      fill: "",
      stroke: "red",
      strokeWidth: 10,
      originY: "center",
      left: x - radius,
      top: y,
      hasControls: false,
      hasBorders: false,
      lockMovementX: lockMovement,
      lockMovementY: lockMovement,
      opacity: 0.5
    });
    this.canvas.add(circle);

    return circle;
  }


  isBetweenX(x: number, x1: number, x2: number, extend: number = 250) {
    if (x1 < x2) {
      return x >= x1 - extend && x <= x2 + extend;
    }

    return x >= x2 - extend && x <= x1 + extend;
  }

  isBetweenY(y: number, y1: number, y2: number, extend: number = 250) {
    if (y1 < y2) {
      return y >= y1 - extend && y <= y2 + extend;
    }

    return y >= y2 - extend && y <= y1 + extend;
  }

  isSymbolAboveOrBelow(lineCoords: LineCoords, symbolCoords: SymbolsCoords) {
    return (
      this.isBetweenY(symbolCoords.y, lineCoords.y1, lineCoords.y2)
      &&
      (
        Math.abs(lineCoords.x1 - symbolCoords.x) < 250 ||
        Math.abs(lineCoords.x2 - symbolCoords.x) < 250
      )
    );
  }

  isSymbolLeftOrRight(lineCoords: LineCoords, symbolCoords: SymbolsCoords) {
    return (
      this.isBetweenX(symbolCoords.x, lineCoords.x1, lineCoords.x2)
      &&
      (
        Math.abs(lineCoords.y1 - symbolCoords.y) < 250 ||
        Math.abs(lineCoords.y2 - symbolCoords.y) < 250
      )
    );
  }

  findSymbolSInProximity(lineCoords: LineCoords) {
    // console.log(this.symbolsCoords, lineCoords)

    return this.symbolsCoords.filter((symbolCoords) => {
      // Draw horizontal line and check if any symbol is above or below
      if (
        this.isSymbolAboveOrBelow(lineCoords, symbolCoords) ||
        this.isSymbolLeftOrRight(lineCoords, symbolCoords)
      ) {
        return true;
      }

      return false;
    });
  }
}
