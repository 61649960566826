import { useGetNodes } from "api/node-analysis";
import Loader from "components/Loader";
import TitleWithAddButton from "components/TitleWithAddButton";
import { useState } from "react";
import { useParams } from "react-router-dom";
import AddNodeModal from "../AddNodeModal";

type Props = {
  isReadOnly: boolean
};

type RouteParams = {
  projectId: string;
};

const NodesListTitle = ({isReadOnly}: Props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { projectId } = useParams<RouteParams>() as RouteParams;
  const { data: nodes } = useGetNodes(projectId);

  if (!nodes) return <Loader />;

  return (
    <TitleWithAddButton
      title={"Nodes list"}
      titleVariant="h4"
      openModal={() => setIsModalOpen(true)}
      buttonText={"Add new node"}
      modal={
        <AddNodeModal
          isOpen={isModalOpen}
          handleClose={() => setIsModalOpen(false)}
        />
      }
      isButtonVisible={!isReadOnly}
      secondaryButtonText="Download nodes list"
      secondaryButtonUrl={`/api/projects/${projectId}/nodes/csv`}
    />
  );
};

export default NodesListTitle;
