import HazopPreparation from "./HazopPreparation";
import HazopSession from "./HazopSession";
//import ProgressTracking from "./ProgressTracking";

const ProjectDetail = () => {
  return (
    <>
      <HazopPreparation />
      <HazopSession />
    </>
  );
};

export default ProjectDetail;
