import CustomRiskClassification from "./classifications/CustomRiskClassification";
import CustomConsequenceCategories from "./consequence-categories/CustomConsequenceCategories";
import CustomLikelihoods from "./likelihood/CustomLikelihoods";
import CustomRiskRank from "./risk-ranks/CustomRiskRank";
import CustomSeverity from "./severity/CustomSeverity";

type Props = {
  isReadOnly: boolean;
}

const CustomRiskMatrix = ({isReadOnly}:Props) => {
  return (
    <>
      <CustomRiskRank isReadOnly={isReadOnly}/>
      <CustomConsequenceCategories isReadOnly={isReadOnly}/>
      <CustomSeverity isReadOnly={isReadOnly}/>
      <CustomLikelihoods isReadOnly={isReadOnly}/>
      <CustomRiskClassification isReadOnly={isReadOnly}/>
    </>
  );
};

export default CustomRiskMatrix;
