import { useMutation, useQuery, useQueryClient } from "react-query";

import axios from "axios";
import { toast } from "react-toastify";
import { TimeframeSession, TimeframeSessionDto } from "types";
import { handleError } from "helpers/handleError";

export const useGetTimeframeSessions = (timeframeId: number) => {
  return useQuery(["timeframes-sessions", timeframeId], async () => {
    const { data } = await axios.get<TimeframeSession[]>(
      `/api/timeframes/${timeframeId}/sessions`
    );
    return data;
  },
  {
    onError: handleError<unknown>
  });
};

export const useAddSession = (timeframeId: number) => {
  const queryClient = useQueryClient();

  return useMutation(
    (newSession: TimeframeSessionDto) =>
      axios.post(`/api/timeframes/${timeframeId}/sessions`, newSession),
    {
      onError: () => {
        toast.error("Something went wrong.");
      },
      onSuccess: () => {
        toast.success("Successfully added new session");
        queryClient.invalidateQueries(["timeframes-sessions", timeframeId]);
      }
    }
  );
};

export const useDeleteSession = (timeframeId: number) => {
  const queryClient = useQueryClient();

  return useMutation(
    (sessionId: number) =>
      axios.delete(`/api/timeframes/${timeframeId}/sessions/${sessionId}`),
    {
      onError: () => {
        toast.error("Something went wrong.");
      },
      onSuccess: () => {
        toast.success("Successfully deleted session");
        queryClient.invalidateQueries(["timeframes-sessions", timeframeId]);
      }
    }
  );
};

export const useEditSession = (timeframeId: number, sessionId: number) => {
  const queryClient = useQueryClient();

  return useMutation(
    (newSession: TimeframeSessionDto) =>
      axios.put(
        `/api/timeframes/${timeframeId}/sessions/${sessionId}`,
        newSession
      ),
    {
      onError: () => {
        toast.error("Something went wrong.");
      },
      onSuccess: () => {
        toast.success("Successfully edited session");
        queryClient.invalidateQueries(["timeframes-sessions", timeframeId]);
      }
    }
  );
};
