import { zodResolver } from "@hookform/resolvers/zod";
import { Grid, TextField } from "@mui/material";
import { validCharacters } from "helpers/commonHelpers";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { useAddOrganization } from "../../api/organizations";
import ModalForm from "../../components/ModalForm";

const OrganizationSchema = z.object({
  companyName: z
    .string()
    .min(1, "Organization name is required")
    .max(256)
    .refine(
      (value) => validCharacters(value),
      "Organization name should contain only alphanumerical characters and &,- or ()"
    ),
  accountId: z.string().max(9, "Account Id cannot be longer than 9 characters"),
  companyCode: z
    .string()
    .max(20, "Company code cannot be longer than 20 characters")
});

type NewOrganization = z.infer<typeof OrganizationSchema>;

type Props = {
  isOpen: boolean;
  handleClose: () => void;
};

const AddOrganizationModal = ({ isOpen, handleClose }: Props) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setError
  } = useForm<NewOrganization>({
    resolver: zodResolver(OrganizationSchema)
  });

  const { mutateAsync, isLoading, error } = useAddOrganization();

  useEffect(() => {
    if (error) {
      setError("companyName", {
        type: "string",
        message: error.response!.data.name
      });
    }
  }, [error, setError]);

  return (
    <ModalForm
      title={"Add organization"}
      isOpen={isOpen}
      handleClose={handleClose}
      onSubmit={handleSubmit(async (newCompany) => {
        await mutateAsync(newCompany);
        reset();
        handleClose();
      })}
      isLoading={isLoading}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            {...register("companyName")}
            error={!!errors.companyName?.message}
            helperText={errors.companyName?.message}
            label={`Organization name *`}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            {...register("accountId")}
            error={!!errors.accountId?.message}
            helperText={errors.accountId?.message}
            label={`Account ID`}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            {...register("companyCode")}
            error={!!errors.companyCode?.message}
            helperText={errors.companyCode?.message}
            label={`Organization code`}
            fullWidth
          />
        </Grid>
      </Grid>
    </ModalForm>
  );
};

export default AddOrganizationModal;
