import { fabric } from "fabric";
import Circles from "./Circle/Circles";
import Rectangles from "./Rectangle/Rectangles";
import { LineCircle } from "./Circle/LineCircle";
import Predictions from "./Predictions";

export interface LineCoords {
  x1: number;
  y1: number;
  x2: number;
  y2: number;
}

export default class Lines {
  constructor(
    private canvas: fabric.Canvas,
    private circles: Circles,
    private rectangles: Rectangles,
    private predictions: Predictions,
    private symbolsIdOnStage: string[] = [],
    public lineCoords: LineCoords[] = []
  ) {}

  addLineCoords(lineCoords: LineCoords) {
    this.lineCoords.push(lineCoords);
  }

  addSymbols(circles: Circles, rectangles: Rectangles) {
    this.circles = circles;
    this.rectangles = rectangles;
    this.symbolsIdOnStage = [];
  }

  createLine({ x1, x2, y1, y2 }: LineCoords) {
    return new fabric.Line([x1 - 15, y1 - 15, x2 - 15, y2 - 15], {
      fill: "red",
      stroke: "red",
      strokeWidth: 30,
      name: `l1`,
      selectable: false,
      hoverCursor: "default",
      opacity: 0.5
    });
  }

  drawLineCircle(x: number, y: number, radius: number, name: string) {
    const circle = new LineCircle({
      type: "line-circle",
      name,
      radius: radius,
      fill: "blue",
      stroke: "blue",
      originY: "center",
      left: x - radius,
      top: y,
      // selectable: false,
      hoverCursor: "pointer",
      opacity: 0.8,
      connectedLineNames: ["l1", "l2"],
      hasRotatingPoint: false,
      hasBorders: false,
      hasControls: false,
      lockMovementX: true,
      lockMovementY: true
    });

    this.canvas.getObjects("line-circle").map((x) => {
      x.setOptions({ fill: "red", stroke: "red" });
      return x;
    });
    this.canvas.add(circle);

    return circle;
  }

  drawLine(isInPreviewMode = false) {
    const coords = this.lineCoords.at(-1) as LineCoords;
    const line = this.createLine(coords);
    this.canvas.add(line);
    line.sendToBack();

    const symbolsInProximity = this.circles.findSymbolSInProximity(coords);

    symbolsInProximity
      .filter(
        (symbol) => this.symbolsIdOnStage.includes("c" + symbol.id) === false
      )
      .forEach((s) => {
        this.symbolsIdOnStage.push("c" + s.id);
        return this.circles.drawCircle(s.x, s.y, 100, isInPreviewMode, s.id);
      });

    const textInProximity = this.rectangles.findSymbolSInProximity(coords);
    textInProximity
      .filter(
        (symbol) => this.symbolsIdOnStage.includes("t" + symbol.id) === false
      )
      .forEach((t) => {
        this.symbolsIdOnStage.push("t" + t.id);
        console.log(this.symbolsIdOnStage);
        this.rectangles.drawRectangle(
          t.x - t.width / 2,
          t.y - t.height / 2,
          t.width,
          t.height,
          "red",
          isInPreviewMode,
          t.id
        );
      });

      this.predictions.onLineAdded(coords);

    this.canvas.renderAll();
  }
}
