import { Typography } from "@mui/material";

type Props = {};

const ProjectNotFound = (props: Props) => {
  return (
    <Typography>
      Requested project was not found. There is a chance that it was deleted.
    </Typography>
  );
};

export default ProjectNotFound;
