import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  Box,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem
} from "@mui/material";
import { useDeleteNode } from "api/node-analysis";
import ConfirmationDialog from "components/ConfirmationDialog";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { NodeDto } from "types";
import EditNodeModal from "./EditNodeModal";

const ITEM_HEIGHT = 48;

type Props = {
  node: NodeDto;
};

type RouteParams = {
  projectId: string;
};

const NodesActionMenu = ({ node }: Props) => {
  const [isNodesModalOpen, setIsEditNodesModalOpen] = useState(false);
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] =
    useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const { projectId } = useParams<RouteParams>() as RouteParams;
  const { mutateAsync: deleteNode } = useDeleteNode(projectId, node.id);

  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: <EditIcon />,
      onClick: () => setIsEditNodesModalOpen(true)
    },
    {
      label: "Delete",
      icon: <DeleteIcon />,
      onClick: () => setIsDeleteConfirmationOpen(true)
    }
  ];

  return (
    <Box>
      <IconButton aria-label="more" id="long-button" onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button"
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        keepMounted
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: "20ch"
          }
        }}
      >
        {MENU_OPTIONS.map((option) => (
          <MenuItem
            key={option.label}
            onClick={(event) => {
              event.stopPropagation();
              option.onClick();
              handleClose(event);
            }}
          >
            <ListItemIcon>{option.icon}</ListItemIcon>
            <ListItemText>{option.label}</ListItemText>
          </MenuItem>
        ))}
      </Menu>

      <Box onClick={(event) => event.stopPropagation()}>
        {isNodesModalOpen && (
          <EditNodeModal
            isOpen={isNodesModalOpen}
            handleClose={() => {
              setIsEditNodesModalOpen(false);
            }}
            node={node}
          />
        )}

        <ConfirmationDialog
          isOpen={isDeleteConfirmationOpen}
          handleClose={() => setIsDeleteConfirmationOpen(false)}
          title={"Delete node?"}
          text={"Are you sure you want to delete the node?"}
          onAgree={() => {
            deleteNode();
            setIsDeleteConfirmationOpen(false);
          }}
        />
      </Box>
    </Box>
  );
};

export default NodesActionMenu;
