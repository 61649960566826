import axios, { AxiosError } from "axios";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { handleError } from "../helpers/handleError";
import {
  ListedParticipantDto,
  NewParticipantDto,
  UpdateParticipantDto
} from "../types";

export const addParticipant = async (
  projectId: string,
  createParticipantDto: NewParticipantDto
) => {
  const { data: participant } = await axios.post<ListedParticipantDto>(
    `/api/projects/${projectId}/participants`,
    createParticipantDto
  );
  return participant;
};

export const useAddParticipant = (projectId: string) => {
  const queryClient = useQueryClient();

  return useMutation(
    (newParticipant: NewParticipantDto) =>
      addParticipant(projectId, newParticipant),
    {
      onError: handleError<{ email: string }>,
      onSuccess: () => {
        toast.success("Successfully added new Participant.");
        queryClient.invalidateQueries(["participants", projectId]);
      }
    }
  );
};

const fetchParticipants = async (projectId: string) => {
  const { data: participant } = await axios.get<ListedParticipantDto[]>(
    `/api/projects/${projectId}/participants`
  );
  return participant;
};

export const useGetParticipants = (projectId: string) => {
  return useQuery<ListedParticipantDto[], AxiosError<unknown>>(
    ["participants", projectId],
    async () => fetchParticipants(projectId),
    {
      onError: handleError<unknown>
    }
  );
};

export const updateParticipant = async (
  projectId: string,
  participantEditDto: UpdateParticipantDto,
  participantId: string
) => {
  const { data: updatedParticipant } = await axios.put<ListedParticipantDto>(
    `/api/projects/${projectId}/participants/${participantId}`,
    participantEditDto
  );
  return updatedParticipant;
};

export const useUpdateParticipant = (
  projectId: string,
  participantId: string
) => {
  const queryClient = useQueryClient();

  return useMutation(
    (participantEditDto: UpdateParticipantDto) =>
      updateParticipant(projectId, participantEditDto, participantId),
    {
      onError: handleError<{ email: string }>,
      onSuccess: () => {
        toast.success("Successfully updated participant.");
        queryClient.invalidateQueries(["participants", projectId]);
      }
    }
  );
};

export const deleteParticipant = async (
  projectId: string,
  participantId: number
) => {
  const { data: isSuccess } = await axios.delete<boolean>(
    `/api/projects/${projectId}/participants/${participantId}`
  );
  return isSuccess;
};

export const useDeleteParticipant = (projectId: string) => {
  const queryClient = useQueryClient();

  return useMutation(
    (participantId: number) => deleteParticipant(projectId, participantId),
    {
      onError: handleError<unknown>,
      onSuccess: () => {
        toast.success("Successfully deleted participant.");
        queryClient.invalidateQueries(["participants", projectId]);
      }
    }
  );
};
