import { zodResolver } from "@hookform/resolvers/zod";
import { Grid, TextField } from "@mui/material";
import { validCharacters } from "helpers/commonHelpers";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { useAddProject } from "../../api/projects";
import ModalForm from "../../components/ModalForm";

const ProjectSchema = z.object({
  name: z
    .string()
    .min(1, "Project name is required")
    .max(256)
    .refine(
      (value) => validCharacters(value),
      "Project name should contain only alphanumerical characters and &,- or ()"
    ),
  projectCode: z.string().max(256).optional()
});

type NewProject = z.infer<typeof ProjectSchema>;

type Props = {
  isOpen: boolean;
  handleClose: () => void;
  assetId: number;
};

const AddProjectModal = ({ isOpen, handleClose, assetId }: Props) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset
  } = useForm<NewProject>({
    resolver: zodResolver(ProjectSchema)
  });
  const { mutateAsync, isLoading } = useAddProject(assetId);

  return (
    <ModalForm
      title={"Add Project"}
      isOpen={isOpen}
      handleClose={handleClose}
      onSubmit={handleSubmit(async (newProject) => {
        await mutateAsync(newProject);
        reset();
        handleClose();
      })}
      isLoading={isLoading}
      isInAccordion
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            {...register("name")}
            error={!!errors.name?.message}
            helperText={errors.name?.message}
            label={`Project name *`}
            fullWidth
            onFocus={(e) => e.stopPropagation()}
            autoFocus
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            {...register("projectCode")}
            error={!!errors.projectCode?.message}
            helperText={errors.projectCode?.message}
            label={`Project code`}
            fullWidth
            onFocus={(e) => e.stopPropagation()}
          />
        </Grid>
      </Grid>
    </ModalForm>
  );
};

export default AddProjectModal;
