import axios from "axios";
import { handleError } from "helpers/handleError";
import { useQuery } from "react-query";

export type AdePredictionItem = {
  id: number;
  fileMetadataId: number;
  predictedClass: string;
  predictedText: string;
  item: string;
  itemNumber: number;
  width: number;
  height: number;
  x1: number;
  x2: number;
  y1: number;
  y2: number;
};

export const useGetAdePredictions = (projectId: string, nodeId: string, fileId: string) => {
  return useQuery(["adepredictions", projectId, nodeId, fileId], async () => {
    const { data } = await axios.get<AdePredictionItem[]>(
      `/api/projects/${projectId}/nodes/${nodeId}/adepredictions/${fileId}`
    );
    return data;
  },
  {
    onError: handleError<unknown>
  });
};
