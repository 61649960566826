import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@mui/material";
import { useGetSelectedMatrixRiskRanks } from "api/matrixes";
import { useState } from "react";
import { useParams } from "react-router-dom";
import {
  CustomRiskRankDto,
  useDeleteRiskRank
} from "../../../../../api/risk-rank";
import ConfirmationDialog from "../../../../../components/ConfirmationDialog";
import TitleWithAddButton from "../../../../../components/TitleWithAddButton";
import AddRiskRankModal from "./AddRiskRankModal";
import EditRiskRankModal from "./EditRiskRankModal";

type RouteParams = {
  projectId: string;
  assetId: string;
};

type Props = {
  isReadOnly: boolean;
  isDnv?: boolean;
};

const CustomRiskRank = ({ isReadOnly, isDnv }: Props) => {
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] =
    useState(false);
  const [selectedCustomRiskRank, setSelectedCustomRiskRanking] =
    useState<CustomRiskRankDto>();

  const { projectId } = useParams<RouteParams>() as RouteParams;

  const { data: customRiskRanks } = useGetSelectedMatrixRiskRanks(projectId);

  const { mutateAsync: deleteRiskRank } = useDeleteRiskRank(
    projectId as string
  );

  return (
    <>
      <TitleWithAddButton
        title={"Risk Rank"}
        textUnderTitle={""}
        modal={
          <AddRiskRankModal
            isOpen={isAddModalOpen}
            handleClose={() => setIsAddModalOpen(false)}
          />
        }
        openModal={() => setIsAddModalOpen(true)}
        buttonText={"Add risk ranking"}
        titleVariant="h4"
        isButtonVisible={!isDnv && !isReadOnly}
      />

      <TableContainer component={Paper}>
        <Table sx={{ tableLayout: "fixed" }}>
          <TableHead>
            <TableRow>
              <TableCell sx={{ width: "10%" }}>Index</TableCell>
              <TableCell sx={{ width: "30%" }}>Name</TableCell>
              <TableCell sx={{ width: "50%" }}>Description</TableCell>
              {!isDnv && !isReadOnly && (
                <>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                </>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {customRiskRanks?.map((customRiskRank) => (
              <TableRow
                key={customRiskRank.name}
                sx={{ backgroundColor: customRiskRank.color }}
              >
                <TableCell>{customRiskRank.index}</TableCell>
                <TableCell>{customRiskRank.name}</TableCell>
                <TableCell>{customRiskRank.description}</TableCell>
                {!isDnv && !isReadOnly && (
                  <>
                    <TableCell
                      align="right"
                      sx={{ padding: "4px", background: "#fff" }}
                    >
                      <IconButton
                        onClick={() => {
                          setIsDeleteConfirmationOpen(true);
                          setSelectedCustomRiskRanking(customRiskRank);
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                    <TableCell
                      align="right"
                      sx={{ padding: "4px", background: "#fff" }}
                    >
                      <IconButton
                        onClick={() => {
                          setIsEditModalOpen(true);
                          setSelectedCustomRiskRanking(customRiskRank);
                        }}
                      >
                        <EditIcon />
                      </IconButton>
                    </TableCell>
                  </>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {selectedCustomRiskRank && isEditModalOpen && (
        <EditRiskRankModal
          isOpen={isEditModalOpen}
          handleClose={() => {
            setIsEditModalOpen(false);
            setSelectedCustomRiskRanking(undefined);
          }}
          riskRanking={selectedCustomRiskRank}
        />
      )}

      <ConfirmationDialog
        isOpen={isDeleteConfirmationOpen}
        handleClose={() => setIsDeleteConfirmationOpen(false)}
        title={"Delete custom risk ranking"}
        text={"Are you sure you want to delete custom risk ranking"}
        onAgree={() => {
          deleteRiskRank(selectedCustomRiskRank!.id);
          setIsDeleteConfirmationOpen(false);
        }}
      />
    </>
  );
};

export default CustomRiskRank;
