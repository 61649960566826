import { DragEndEvent } from "@dnd-kit/core";
import { arrayMove } from "@dnd-kit/sortable";
import axios, { AxiosError } from "axios";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { handleError } from "../helpers/handleError";
import { Deviation } from "../types";

interface DeviationEditDto {
  guideword: string;
  parameter: string;
  interpretation?: string;
}

export const useGetDeviations = (projectId: string) => {
  return useQuery<Deviation[], AxiosError<unknown>>(["deviations", projectId], async () => {
    const { data } = await axios.get<Deviation[]>(
      `/api/projects/${projectId}/deviations`
    );
    return data;
  },
  {
    onError: handleError<unknown>
  });
};

// TODO: Remove once type is generated by api
type DeviationAddDto = {
  guideword: string;
  parameter: string;
  interpretation?: string;
};

export const addDeviation = async (
  projectId: string,
  newDeviation: DeviationAddDto
) => {
  const { data: deviation } = await axios.post<Deviation>(
    `/api/projects/${projectId}/deviations`,
    newDeviation
  );
  return deviation;
};

export const useAddDeviation = (projectId: string) => {
  const queryClient = useQueryClient();

  return useMutation(
    (newDeviation: DeviationAddDto) => addDeviation(projectId, newDeviation),
    {
      onError: handleError<{ guideword?: string; parameter?: string }>,
      onSuccess: () => {
        toast.success("Successfully added new deviation.");
        queryClient.invalidateQueries(["deviations", projectId]);
      }
    }
  );
};

export const editDeviation = async (
  projectId: string,
  updatedDeviation: DeviationEditDto,
  deviationId: number
) => {
  const { data: organization } = await axios.put<Deviation>(
    `/api/projects/${projectId}/deviations/${deviationId}`,
    updatedDeviation
  );
  return organization;
};

export type UseEditDeviationParams = {
  updatedDeviation: DeviationEditDto;
  deviationId: number;
};
export const useEditDeviation = (projectId: string) => {
  const queryClient = useQueryClient();

  return useMutation(
    (useEditDeviationParams: UseEditDeviationParams) =>
      editDeviation(
        projectId,
        useEditDeviationParams.updatedDeviation,
        useEditDeviationParams.deviationId
      ),
    {
      onError: handleError<{ guideword?: string; parameter?: string }>,
      onSuccess: () => {
        toast.success("Successfully edited deviation.");
        queryClient.invalidateQueries(["deviations", projectId]);
      }
    }
  );
};

export const deleteDeviation = async (
  projectId: string,
  deviationId: number
) => {
  const { data: isSuccess } = await axios.delete<boolean>(
    `/api/projects/${projectId}/deviations/${deviationId}`
  );
  return isSuccess;
};

export const useDeleteDeviation = (projectId: string) => {
  const queryClient = useQueryClient();

  return useMutation(
    (deviationId: number) => deleteDeviation(projectId, deviationId),
    {
      onError: handleError<unknown>,
      onSuccess: () => {
        toast.success("Successfully deleted deviation.");
        queryClient.invalidateQueries(["deviations", projectId]);
      }
    }
  );
};

export interface DeviationPatchDto {
  selected?: boolean;
  sequence?: number;
}

// const calculateOptimistic = (previousDeviations: Deviation[]) => {
//   return optimistic;
// };

export const patchDeviation = async (
  projectId: string,
  deviationId: number,
  deviationPatch: DeviationPatchDto
) => {
  // TODO: Change response once backend is done
  const { data: isSuccess } = await axios.patch<Deviation>(
    `/api/projects/${projectId}/deviations/${deviationId}`,
    deviationPatch
  );
  return isSuccess;
};

export type UsePatchDeviationParams = {
  deviationPatch: DeviationPatchDto;
  deviationId: number;
  event?: DragEndEvent;
};

export const usePatchDeviation = (projectId: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (usePatchDeviationParams: UsePatchDeviationParams) =>
      patchDeviation(
        projectId,
        usePatchDeviationParams.deviationId,
        usePatchDeviationParams.deviationPatch
      ),
    // When mutate is called:
    onMutate: async (usePatchDeviationParams) => {
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({
        queryKey: ["deviations", projectId]
      });

      // Snapshot the previous value
      const previousDeviations = queryClient.getQueryData<Deviation[]>([
        "deviations",
        projectId
      ]);

      if (usePatchDeviationParams.deviationPatch.selected !== undefined) {
        const optimistic = previousDeviations
          ?.map((previousDeviation) =>
            previousDeviation.id === usePatchDeviationParams.deviationId
              ? {
                  ...previousDeviation,
                  ...usePatchDeviationParams.deviationPatch
                }
              : previousDeviation
          )
          .sort((a, b) => a.sequence - b.sequence);
        // Optimistically update to the new value
        queryClient.setQueryData(["deviations", projectId], optimistic);
      } else if (
        usePatchDeviationParams.deviationPatch.sequence !== undefined &&
        previousDeviations &&
        usePatchDeviationParams.event
      ) {
        const { active, over } = usePatchDeviationParams.event;
        const oldIndex = previousDeviations.findIndex(
          (sd) => sd!.id === active!.id
        );
        const newIndex = previousDeviations.findIndex(
          (sd) => sd!.id === over!.id
        );
        const optimistic = arrayMove(
          previousDeviations,
          oldIndex,
          newIndex
        )?.map((deviation, i) => ({ ...deviation, sequence: i + 1 }));

        queryClient.setQueryData(["deviations", projectId], optimistic);
      }

      // Return a context with the previous and new deviation
      return {
        previousDeviations: previousDeviations,
        patchedDeviation: usePatchDeviationParams
      };
    },
    onError: (err, patchedDeviation, context) => {
      toast.error("Something went wrong.");
      queryClient.invalidateQueries(
        ["deviations", projectId]
        // If the mutation fails, use the context we returned above
        // context?.previousDeviations
      );
    }
    // Always refetch after error or success:
    // onSettled: (_) => {
    //   queryClient.invalidateQueries({ queryKey: ["deviations", projectId] });
    // }
  });
};
