import { Box, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { useGetSelectedMatrixLikelihoods } from "api/likelihood";
import { useGetSelectedSeverity } from "api/severity";
import Loader from "components/Loader";
import { useParams } from "react-router-dom";
import Squares from "./Squares";
import { CELL_LENGTH, OFFSET } from "./constants";
import { useEffect } from "react";

type RouteProps = {
  projectId: string;
};

type Props = {
  isReadOnly: boolean;
  isDnv?: boolean;
};

const CustomRiskClassification = ({ isReadOnly, isDnv }: Props) => {
  const { projectId } = useParams<RouteProps>() as RouteProps;
  const { data: likelihoods } = useGetSelectedMatrixLikelihoods(projectId);
  const { data: severities } = useGetSelectedSeverity(projectId);

  useEffect(() => {
    if (document.location.hash !== '')
      document.querySelector(document.location.hash)?.scrollIntoView(
        { behavior: "smooth", inline: "nearest" }
      );
  }, [likelihoods, severities])

  if (!likelihoods || !severities) return <Loader />;

  return (
    <Box sx={{ marginLeft: "1rem" }} id="risk-classification">
      <Typography variant="h4">Risk classification</Typography>

      <Typography
        variant="h6"
        sx={{
          transform: `rotate(90deg)`,
          transformOrigin: `0 0`,
          width: severities.length * CELL_LENGTH + 150,
          textAlign: "center",
          position: "relative"
          // marginBottom: `${CELL_LENGTH}px`
        }}
      >
        Severities
      </Typography>

      <Box sx={{ marginLeft: `${OFFSET}px` }}>
        <Typography
          variant="h6"
          sx={{
            textAlign: "center",
            width: likelihoods.length * CELL_LENGTH
          }}
        >
          Likelihood
        </Typography>
        <Box sx={{ display: "flex" }}>
          {likelihoods.map((likelihood) => {
            return (
              <Typography
                key={likelihood.id}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: OFFSET,
                  width: CELL_LENGTH,
                  borderTop: `1px solid ${grey[500]}`,
                  color: grey[700]
                }}
              >
                {likelihood.index}
              </Typography>
            );
          })}
        </Box>
      </Box>

      <Squares isDnv={isDnv} isReadOnly={isReadOnly} />
    </Box>
  );
};

export default CustomRiskClassification;
