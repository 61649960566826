import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  DataGrid,
  GridActionsCellItem,
  GridEnrichedColDef,
  GridRowsProp
} from "@mui/x-data-grid";
import Loader from "components/Loader";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { useDeleteUser, useDeleteUserFromOrganization } from "api/users";
import { ViewedUser } from "types";
import EditUserModal from "./EditUserModal";
import ConfirmationDialog from "components/ConfirmationDialog";

type Props = {
  users: ViewedUser[];
  isEditable: boolean;
  isAdminPage?: boolean;
};

type RouteParams = {
  organizationId: string;
};

const UsersDatagrid = ({ users, isEditable, isAdminPage }: Props) => {
  const { organizationId } = useParams<RouteParams>() as RouteParams;

  const [isEditVisible, setIsEditVisible] = useState(false);
  const [isDeleteUserVisible, setIsDeleteUserVisible] = useState(false);
  const [isDeleteFromServiceVisible, setIsDeleteFromServiceVisible] = useState(false);
  const [selectedUser, setSelectedUser] = useState<ViewedUser | null>(null);

  const { mutateAsync: deleteUserFromOrganization } = useDeleteUserFromOrganization(organizationId);
  const { mutateAsync: deleteUser } = useDeleteUser();

  if (!users) return <Loader />;

  const rows: GridRowsProp =
    users.map((user) => ({
      id: user.id,
      email: user.email,
      name: user.userName,
      role: user.roleName,
      roleId: user.roleId,
      organizationName: user.organizationName
    })) ?? [];

  const actionColumns: GridEnrichedColDef = {
    field: "actions",
    type: "actions",
    getActions: (params) => (!isEditable) ? [
      <GridActionsCellItem
        icon={<EditIcon />}
        onClick={() => {
          setIsEditVisible(true);
          setSelectedUser(params.row);
        }}
        label="Edit"
      />] : [
      <GridActionsCellItem
        icon={<EditIcon />}
        onClick={() => {
          setIsEditVisible(true);
          setSelectedUser(params.row);
        }}
        label="Edit"
      />,
      <GridActionsCellItem
        icon={< DeleteIcon />}
        onClick={() => {
          setSelectedUser(params.row);
          if (isAdminPage)
            setIsDeleteFromServiceVisible(true);
          else
            setIsDeleteUserVisible(true);

        }}
        label="Delete"
      />
    ]
  };
  const columns: GridEnrichedColDef[] = [
    {
      flex: 1,
      field: "email",
      headerName: "Email"
    },
    {
      flex: 1,
      field: "name",
      headerName: "Name"
    },
    {
      flex: 1,
      field: "role",
      headerName: "Role"
    }
  ];

  if (isAdminPage)
    columns.push({
      flex: 1,
      field: "organizationName",
      headerName: "Organization"
    });

  const finalColumns = isEditable ? [...columns, actionColumns] : columns;

  return (
    <>
      <DataGrid
        rows={rows}
        columns={finalColumns}
        autoHeight
        disableSelectionOnClick
      />
      {isEditVisible && selectedUser && (
        <EditUserModal
          isOpen={isEditVisible}
          handleClose={() => {
            setIsEditVisible(false);
            setSelectedUser(null);
          }}
          user={selectedUser}
          isUserPanel={!isAdminPage}
        />
      )}
      <ConfirmationDialog
        isOpen={isDeleteUserVisible}
        handleClose={() => setIsDeleteUserVisible(false)}
        title={"Delete user"}
        text={"Are you sure you want to delete user from organization?"}
        onAgree={() => {
          // add delete 
          if (selectedUser) deleteUserFromOrganization(selectedUser.id)
          setIsDeleteUserVisible(false);
        }}
      />
      <ConfirmationDialog
        isOpen={isDeleteFromServiceVisible}
        handleClose={() => setIsDeleteFromServiceVisible(false)}
        title={"Delete user"}
        text={"Are you sure you want to delete user from service?"}
        onAgree={() => {
          if (selectedUser) deleteUser(selectedUser.id)
          setIsDeleteFromServiceVisible(false);
        }}
      />
    </>
  );
};

export default UsersDatagrid;
