import { Box, CssBaseline } from "@mui/material";
import { FORBIDDEN, UNAUTHORIZED } from "helpers/handleError";
import { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Header from "./Header/Header";
import Loader from "./Loader";
import NotAllowed from "./NotAllowed";

export const PADDING_LEFT_RIGHT = 20;

const Root = () => {
  const [isFetchingAuth, setIsFetchingAuth] = useState(true);
  const [isForbidden, setisForbidden] = useState(false);

  useEffect(() => {
    fetch("/isauth").then((res) => {
      if (res.status === FORBIDDEN) {
        setisForbidden(true);
      }

      if (res.status === UNAUTHORIZED) {
        if (process.env.NODE_ENV === "development") {
          window.location.href = `https://localhost:44497/signin`;
        } else {
          window.location.href = "/signin";
        }
      } else {
        setIsFetchingAuth(false);
      }
    });
  }, []);

  if (isFetchingAuth) return <Loader />;
  if (isForbidden) return <NotAllowed />;

  return (
    <>
      <CssBaseline />
      <ToastContainer />
      <Header />
      <Box
        sx={{
          paddingBottom: "2rem",
          paddingTop: "5rem",
          paddingLeft: `${PADDING_LEFT_RIGHT}px`,
          paddingRight: `${PADDING_LEFT_RIGHT}px`,
          minHeight: "95vh"
          // minWidth: "95vw"
        }}
      >
        <Outlet />
      </Box>
      {/* <Footer /> */}
    </>
  );
};

export default Root;
