import DownloadIcon from "@mui/icons-material/Download";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import { Box, Button, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { useTheme } from "@mui/material/styles";
import { downloadBlob } from "helpers/commonHelpers";
import { FileMetaData } from "types";

type Props = { file: FileMetaData };

const DownloadableFileWithIcon = ({ file }: Props) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginRight: theme.spacing(1)
      }}
    >
      <InsertDriveFileIcon style={{ fontSize: 35, color: grey[500] }} />
      <Typography
        variant="body2"
        align="center"
        sx={{ marginTop: theme.spacing(1) }}
      >
        {file.fileName}
      </Typography>
      <Button
        onClick={() => {
          downloadBlob(
            file.fileName,
            `/api/StudySetup/${file.projectId}/DownloadFile/${file.documentCategoryId}/${file.fileName}`,
            { type: "application/pdf" }
          );
        }}
      >
        Download
        <DownloadIcon />
      </Button>
    </Box>
  );
};

export default DownloadableFileWithIcon;
