import { Grid } from "@mui/material";
import DownloadableFileWithIcon from "../../../components/DownloadableFileWithIcon";
import { FileMetaData } from "../../../types";

type Props = {
  uploadedFiles: FileMetaData[];
};

const UploadedFilesList = ({ uploadedFiles }: Props) => {
  return (
    <>
      <Grid container spacing={1}>
        {uploadedFiles.map((file, index) => (
          <Grid item key={index}>
            <DownloadableFileWithIcon file={file} />
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default UploadedFilesList;
