import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  Grid,
  ListItemText,
  Typography
} from "@mui/material";
import { grey, lightBlue } from "@mui/material/colors";
import { Box } from "@mui/system";
import { useGetNodes } from "api/node-analysis";
import { useParams } from "react-router-dom";
import { NodeDto } from "types";
import NodesActionMenu from "./NodesActionMenu";
import DrawingsList from "./drawings-list/DrawingsList";

type Props = {
  isReadOnly: boolean
};

export type NodeListDetailProps = {
  node: NodeDto;
};
const NodeListDetail = ({ node }: NodeListDetailProps) => {
  return (
    <Grid container direction="column" spacing={2} sx={{ flex: 1 }}>
      <Grid item>
        <Typography fontWeight={"500"}>Node ID</Typography>
        <ListItemText>{node.nodeIdentifier}</ListItemText>
      </Grid>
      <Grid item>
        <Typography fontWeight={"500"}>Node name</Typography>
        <ListItemText>{node.name}</ListItemText>
      </Grid>

      <Grid item>
        <Typography fontWeight={"500"}>Design intention</Typography>
        <ListItemText>{node.designIntention}</ListItemText>
      </Grid>
      <Grid item>
        <Typography fontWeight={"500"}>Operating conditions</Typography>
        <ListItemText>{node.operatingConditions}</ListItemText>
      </Grid>
      <Grid item>
        <Typography fontWeight={"500"}>Comment</Typography>
        <ListItemText>{node?.comments || "No comment"}</ListItemText>
      </Grid>
    </Grid>
  );
};

type RouteParams = {
  projectId: string;
};

const NodesList = ({isReadOnly}: Props) => {
  const { projectId } = useParams<RouteParams>() as RouteParams;
  const { data: nodes, isLoading } = useGetNodes(projectId);

  if (!isLoading && nodes?.length === 0) {
    return <Typography>No nodes</Typography>;
  }

  return (
    <>
      {nodes?.map((node) => (
        <Accordion key={node.id}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            sx={{
              width: "100%",
              "&.Mui-expanded": {
                background: lightBlue[50]
              }
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%"
              }}
            >
              <Typography variant="h5">{node.name}</Typography>
              {!isReadOnly && (<NodesActionMenu node={node} />)}
            </Box>
          </AccordionSummary>
          <Divider />
          <AccordionDetails
            sx={{
              borderBottom: `1px solid ${grey[300]}`,
              display: "flex",
              justifyContent: "space-between",
              paddingRight: 0
            }}
          >
            <NodeListDetail node={node} />
            <DrawingsList drawings={node.files} nodeId={node.id} />
          </AccordionDetails>
        </Accordion>
      ))}
    </>
  );
};

export default NodesList;
