import axios from "axios";
import { handleError } from "helpers/handleError";
import { useMutation, useQuery, useQueryClient } from "react-query";

// Needed because of lack of classification's id from the server
const isTheSameClassification = (
  x: RawScenarioRiskClassification,
  y: RawScenarioRiskClassification
) => {
  return x.likelihoodId === y.likelihoodId && x.severityId === y.severityId;
};

export interface RawScenarioRiskClassification {
  likelihoodId: number;
  severityId: number;
  riskRankId: number;
}

export const useGetMatrixClassifications = (
  projectId: string,
  riskMatrixId: number | undefined
) => {
  return useQuery(
    ["classifications", projectId],
    async () => {
      if (!riskMatrixId) return [];
      const { data } = await axios.get<RawScenarioRiskClassification[]>(
        `/api/projects/${projectId}/matrixes/${riskMatrixId}/classifications`
      );
      return data;
    },
    {
      enabled: !!riskMatrixId,
      cacheTime: 1,
      onError: handleError<unknown>
    }
  );
};

export interface UpsertMatrixClassificationDto {
  likelihoodId: number;
  severityId: number;
  riskRankId: number;
}

export const useUpsertMatrixClassification = (
  projectId: string,
  riskMatrixId: number | undefined
) => {
  const queryClient = useQueryClient();

  return useMutation(
    async (newMatrixClassification: UpsertMatrixClassificationDto) => {
      const { data } = await axios.post<RawScenarioRiskClassification>(
        `/api/projects/${projectId}/matrixes/${riskMatrixId}/classifications`,
        newMatrixClassification
      );
      return data;
    },
    {
      onMutate: async (
        newMatrixClassification: UpsertMatrixClassificationDto
      ) => {
        await queryClient.cancelQueries({
          queryKey: ["classifications", projectId]
        });

        const previousClassifications = queryClient.getQueryData<
          RawScenarioRiskClassification[]
        >(["classifications", projectId]);

        const optimistic = previousClassifications?.map((x) => {
          if (isTheSameClassification(newMatrixClassification, x)) {
            return newMatrixClassification;
          }
          return x;
        });

        queryClient.setQueryData(["classifications", projectId], optimistic);
      },
      onError: handleError<unknown>,
      onSuccess: () => {
        // toast.success("Successfully updated matrix classification.");
        queryClient.invalidateQueries(["classifications", projectId]);
      }
    }
  );
};
