import axios from "axios";
import { useQuery } from "react-query";
import { Role } from "../types";

export const useGetAppRoles = () => {
  return useQuery("roles", async () => {
    const { data } = await axios.get<Role[]>(`/api/roles/app`);
    return data;
  });
};

export const useGetProjectRoles = () => {
  return useQuery("project-roles", async () => {
    const { data } = await axios.get<Role[]>(`/api/roles/project`);
    return data;
  });
};
