import { createTheme, ThemeProvider } from "@mui/material/styles";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

import { createRoot } from "react-dom/client";

import { QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { router } from "./router";

import "react-toastify/dist/ReactToastify.css";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import { queryClient } from "./api/client";

import { RouterProvider } from "react-router";

import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

// if (process.env.NODE_ENV === "development") {
//   const { worker } = require("./mocks/browser");
//   worker.start();
// }

const theme = createTheme();

const container = document.getElementById("root");
const root = createRoot(container as Element);

// TODO: Bring back strict mode once done with the canvas
// Remove it if you want to work with canvas
root.render(
  <LocalizationProvider dateAdapter={AdapterMoment}>
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <RouterProvider router={router} />
      </ThemeProvider>
      <ReactQueryDevtools />
    </QueryClientProvider>
  </LocalizationProvider>
);
