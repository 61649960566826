import { useGetProject } from "api/projects";
import { ProjectAssetIdRouteProps } from "common/routeParams";
import ProjectNotFound from "components/ProjectNotFound";
import { BAD_REQUEST, NOT_FOUND } from "helpers/handleError";
import { Outlet, useParams } from "react-router-dom";
import ProjectsBreadcrumbs from "../project/ProjectBreadcrumbs";
import Sidebar from "./Sidebar";

type Props = {};

const StudySetup = (props: Props) => {
  const { assetId, projectId } = useParams<ProjectAssetIdRouteProps>() as ProjectAssetIdRouteProps;
  const { error } = useGetProject(assetId, projectId);

  const status = error?.response?.status;
  const showError = status === NOT_FOUND || status === BAD_REQUEST;

  return (
    <>
      <Sidebar>
        <>
          <ProjectsBreadcrumbs />
          {showError && <ProjectNotFound />}
          {!showError && <Outlet />}
        </>
      </Sidebar>
    </>
  );
};

export default StudySetup;
