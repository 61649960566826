import DescriptionIcon from "@mui/icons-material/Description";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FolderIcon from "@mui/icons-material/Folder";
import {
  Accordion,
  AccordionSummary,
  Divider,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText
} from "@mui/material";
import { useGetDocumentCategories } from "api/document-categories";
import { useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";

type RouteProps = {
  projectId: string;
  assetId: string;
  documentCategoryId: string;
};

const DocumentCategoriesAccordion = () => {
  const { projectId, assetId, documentCategoryId } =
    useParams<RouteProps>() as RouteProps;
  const location = useLocation();
  const { data: documentCategories } = useGetDocumentCategories(projectId);
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    if (location.pathname.includes("documents")) {
      setExpanded(true);
    }
  }, [location.pathname]);

  return (
    <Accordion expanded={expanded}>
      <ListItem
        disablePadding
        onClick={() => setExpanded((prevExpanded) => !prevExpanded)}
      >
        <ListItemButton>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            sx={{ padding: 0, flexGrow: 1 }}
          >
            <ListItemIcon sx={{ minWidth: "30px" }}>
              <FolderIcon />
            </ListItemIcon>
            <ListItemText primary="Documents" />
          </AccordionSummary>
        </ListItemButton>
      </ListItem>
      <Divider />
      {documentCategories?.map((item) => (
        <Link
          to={`/assets/${assetId}/projects/${projectId}/studysetup/documentsCategories/${item.id}`}
          style={{ textDecoration: "none", color: "inherit" }}
          // @ts-ignore
          key={item.categoryName}
        >
          <ListItem disablePadding>
            <ListItemButton selected={documentCategoryId === `${item.id}`}>
              <ListItemIcon sx={{ minWidth: "30px" }}>
                <DescriptionIcon />
              </ListItemIcon>
              <ListItemText
                // @ts-ignore
                primary={`${item.categoryName} (${item.uploadedDocuments})`}
              />
            </ListItemButton>
          </ListItem>
        </Link>
      ))}
    </Accordion>
  );
};

export default DocumentCategoriesAccordion;
