import { Box, Tab, Tabs, Typography } from "@mui/material";
import Title from "components/Title";
import { useEffect, useState } from "react";
import { Link, Outlet, useLocation } from "react-router-dom";

type Props = {};

const ManageNodesDescription = () => (
  <Typography sx={{ marginTop: "0.5rem", marginBottom: "2rem" }}>
    View and edit nodes, symbols and equipments
  </Typography>
);

const MarkupNodeDescription = () => (
  <Box sx={{ marginTop: "0.5rem", marginBottom: "2rem" }}>
    <Typography>
      <strong>Document Name / Number :</strong> 537145810{" "}
    </Typography>
    <Typography>
      {" "}
      <strong>Node ID - Name</strong> : 1 - Node-01
    </Typography>
  </Box>
);

const NodeAnalysisTabs = () => {
  const [tab, setTab] = useState(0);

  const { pathname } = useLocation();

  // When user refreshes make sure the url sets active tab properly
  useEffect(() => {
    if (pathname.includes("pids")) setTab(1);
    else if (pathname.includes("nodes")) setTab(0);
    else setTab(2);

  }, [pathname]);

  return (
    <Box sx={{ textAlign: "center" }}>
      <Tabs value={tab} onChange={(_, newValue) => setTab(newValue)} centered>
        <Tab
          label="Manage nodes"
          to="nodes"
          component={Link}
          sx={{ paddingBottom: 0 }}
        />
        {tab === 1 && <Tab
          label="Node Markup"
          to={pathname}
          component={Link}
          sx={{ paddingBottom: 0 }}
        />}
      </Tabs>
      {tab === 0 && <ManageNodesDescription />}
      {tab === 1 && <MarkupNodeDescription />}
    </Box>
  );
};

const NodeAnalysis = (props: Props) => {
  return (
    <Box sx={{ minHeight: "100vh" }}>
      <Title>
        <>
          <Box>
            <Typography variant={"h3"}>Node analysis</Typography>
          </Box>
          <Typography color={"GrayText"}>
            Analyze P&IDs, add nodes and manage worksheet pre-population
          </Typography>
        </>
      </Title>

      <NodeAnalysisTabs />

      <Box sx={{ width: "100%" }}>
        <Outlet />
      </Box>
    </Box>
  );
};

export default NodeAnalysis;
