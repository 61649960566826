import Box from "@mui/material/Box";
import blue from "@mui/material/colors/blue";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import React from "react";
import { Link } from "react-router-dom";

type Props = {
  title: string;
  text: string;
  icon: JSX.Element;
  to: string;
};

const ProjectDetailLinkItem = ({ title, text, icon, to }: Props) => {
  return (
    <Grid item xs={6} lg={4} xl={3}>
      <Box
        component={Link}
        to={to}
        sx={{
          boxShadow: "0px 6px 23px -10px #0F204B33",
          height: "100%",
          display: "flex",
          color: "inherit",
          textDecoration: "none",
          border: "1px solid transparent",
          "&:hover": {
            border: `1px solid ${blue[500]}`
          }
        }}
        p={3}
        pt={6}
        pb={6}
      >
        <Grid container spacing={2}>
          <Grid item xs={3} sx={{ display: "flex", alignItems: "center" }}>
            {React.cloneElement(icon, {
              sx: {
                width: "69px",
                height: "69px",
                color: blue[500]
              }
            })}
          </Grid>

          <Grid
            item
            xs={9}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center"
            }}
          >
            <Typography sx={{ width: "100%" }} variant="h6" fontWeight="bold">
              {title}
            </Typography>
            <Typography variant="body1" sx={{ color: "text.secondary" }}>
              {text}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Grid>
  );
};

export default ProjectDetailLinkItem;
