import AnalyzePid from "./fabric/AnalyzePid";

type Props = {
  edit?: boolean
};

const Pids = ({ edit = true }: Props) => {
  if (edit === false) return <AnalyzePid showResult={true} />;
  return <AnalyzePid />;
};

export default Pids;
