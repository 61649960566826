import axios from "axios";
import { handleError } from "helpers/handleError";
import { useMutation, useQuery } from "react-query";

export type NewLineCoordsItem = {
  x1: number;
  x2: number;
  y1: number;
  y2: number;
};

export type LineCoordsItem = {
  id: number;
  x1: number;
  x2: number;
  y1: number;
  y2: number;
};

export const useGetLineCoords = (nodeId: string, fileId: string) => {
  return useQuery(["line-coords", nodeId, fileId], async () => {
    const { data } = await axios.get<LineCoordsItem[]>(
      `/api/files/${fileId}/markups/${nodeId}`
    );
    return data;
  },
  {
    onError: handleError<unknown>
  });
};

export const useAddLineCoords = (nodeId: string, fileId: string) => {
  return useMutation(
    async (newLineCoords: NewLineCoordsItem) => {
      const { data: lineCoordsFromApiResponse } =
        await axios.post<LineCoordsItem>(
          `/api/files/${fileId}/markups/${nodeId}`,
          newLineCoords
        );
      return lineCoordsFromApiResponse;
    },
    {
      onError: handleError<unknown>
    }
  );
};

export const useDeleteLineCoords = (nodeId: string, fileId: string) => {
  return useMutation(
    async () => {
      const { data } = await axios.delete(
        `/api/files/${fileId}/markups/${nodeId}`
      );
      return data;
    },
    {
      onError: handleError<unknown>
    }
  );
};
