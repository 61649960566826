import { zodResolver } from "@hookform/resolvers/zod";
import { Autocomplete, Grid, TextField } from "@mui/material";
import { DesktopDateTimePicker } from "@mui/x-date-pickers";
import { useGetParticipants } from "api/participants";
import { useAddSession } from "api/timeframe-sessions";
import ModalForm from "components/ModalForm";
import moment from "moment";
import { Controller, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { TimeframeDto } from "types";
import { z } from "zod";

const UserSchema = z.object({
  sessionTime: z.coerce.date(),
  durationMinutes: z.number().min(1),
  facilitatorId: z.number(),
  scribeId: z.number(),
  comments: z.string(),
  participantIds: z.number().array()
});

type NewSessionType = z.infer<typeof UserSchema>;

type Props = {
  isOpen: boolean;
  handleClose: () => void;
  timeframe: TimeframeDto;
};

type RouteParams = {
  projectId: string;
  assetId: string;
};

const AddSessionModal = ({ isOpen, handleClose, timeframe }: Props) => {
  const {
    control,
    formState: { errors },
    handleSubmit,
    register,
    reset
  } = useForm<NewSessionType>({
    resolver: zodResolver(UserSchema)
  });
  const { mutateAsync: addSession, isLoading } = useAddSession(timeframe.id);
  const { projectId } = useParams<RouteParams>() as RouteParams;
  const { data: participants } = useGetParticipants(projectId);

  return (
    <ModalForm
      title={"Add Session"}
      isOpen={isOpen}
      handleClose={handleClose}
      onSubmit={handleSubmit(async (newSession) => {
        const sessionTime = moment.utc(newSession.sessionTime).toISOString();
        await addSession({ ...newSession, sessionTime });
        reset();
        handleClose();
      })}
      isLoading={isLoading}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Controller
            name="sessionTime"
            control={control}
            // @ts-ignore
            defaultValue={null}
            render={({ field }) => (
              <DesktopDateTimePicker
                sx={{ width: "100%" }}
                label="Session"
                value={field.value}
                format={"MM/DD/YYYY hh:mm"}
                onChange={(newValue) => field.onChange(newValue)}
                shouldDisableDate={(date) =>
                  moment(date).isBefore(moment(timeframe.startDate).add(-1, 'day')) || moment(date).isAfter(moment(timeframe.endDate))
                }
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            {...register("durationMinutes", {
              valueAsNumber: true
            })}
            inputMode="numeric"
            error={!!errors.durationMinutes?.message}
            helperText={errors.durationMinutes?.message}
            label={`Duration (minutes)*`}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="facilitatorId"
            control={control}
            // @ts-ignore
            defaultValue={null}
            render={({ field }) => (
              <Autocomplete
                onChange={(_, newFacilitatorId) => {
                  field.onChange(newFacilitatorId);
                }}
                value={field.value}
                options={
                  participants?.map((participant) => participant.userId) ?? []
                }
                getOptionLabel={(option) =>
                  participants?.find(
                    (participant) => participant.userId === option
                  )?.name ?? ""
                }
                renderInput={(params) => (
                  <TextField {...params} label={"Facilitator *"} />
                )}
              />
            )}
          />
        </Grid>

        <Grid item xs={12}>
          <Controller
            name="scribeId"
            control={control}
            // @ts-ignore
            defaultValue={null}
            render={({ field }) => (
              <Autocomplete
                // {...field}
                onChange={(_, newScribeId) => {
                  field.onChange(newScribeId);
                }}
                value={field.value}
                options={
                  participants?.map((participant) => participant.userId) ?? []
                }
                getOptionLabel={(option) =>
                  participants?.find(
                    (participant) => participant.userId === option
                  )?.name ?? ""
                }
                renderInput={(params) => (
                  <TextField {...params} label={"Scribe *"} />
                )}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            {...register("comments")}
            error={!!errors.comments?.message}
            helperText={errors.comments?.message}
            label={`Comments`}
            fullWidth
          />
        </Grid>

        <Grid item xs={12}>
          <Controller
            name="participantIds"
            control={control}
            // @ts-ignore
            defaultValue={[]}
            render={({ field }) => (
              <Autocomplete
                onChange={(_, newParticipantIds) => {
                  field.onChange(newParticipantIds);
                }}
                value={field.value}
                options={
                  participants?.map((participant) => participant.userId) ?? []
                }
                getOptionLabel={(option) =>
                  participants?.find(
                    (participant) => participant.userId === option
                  )?.name ?? ""
                }
                renderInput={(params) => (
                  <TextField {...params} label={"Participants *"} />
                )}
                multiple
              />
            )}
          />
        </Grid>
      </Grid>
    </ModalForm>
  );
};

export default AddSessionModal;
