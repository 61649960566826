import { zodResolver } from "@hookform/resolvers/zod";
import { Grid, TextField } from "@mui/material";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { useEditConsequenceCategory } from "../../../../../api/matrixes";
import ModalForm from "../../../../../components/ModalForm";
import { ConsequenceCategory } from "../../../../../types";
import {
  ConsequenceCategorySchema,
  NewConsequenceCategory
} from "./AddConsequenceCategoryModal";

type Props = {
  isOpen: boolean;
  handleClose: () => void;
  customConsequenceCategory: ConsequenceCategory;
};

type RouteParams = {
  projectId: string;
};

const EditCustomConsequenceCategoryModal = ({
  isOpen,
  handleClose,
  customConsequenceCategory
}: Props) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset
  } = useForm<NewConsequenceCategory>({
    resolver: zodResolver(ConsequenceCategorySchema),
    defaultValues: {
      index: customConsequenceCategory.index,
      name: customConsequenceCategory.name
    }
  });

  const { projectId } = useParams<RouteParams>() as RouteParams;
  const { mutateAsync, isLoading } = useEditConsequenceCategory(
    projectId,
    customConsequenceCategory.id
  );

  // useEffect(() => {
  //   if (error?.response?.data.guideword) {
  //     setError("guideword", {
  //       type: "string",
  //       message: error.response!.data.guideword
  //     });
  //   }
  //   if (error?.response?.data.parameter) {
  //     setError("parameter", {
  //       type: "string",
  //       message: error.response!.data.parameter
  //     });
  //   }
  // }, [error, setError]);

  return (
    <ModalForm
      title={"Edit custom consequence category"}
      isOpen={isOpen}
      handleClose={handleClose}
      onSubmit={handleSubmit(async (updatedConsequenceCategory) => {
        await mutateAsync(updatedConsequenceCategory);
        reset();
        handleClose();
      })}
      isLoading={isLoading}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            {...register("index", {
              valueAsNumber: true
            })}
            inputMode="numeric"
            error={!!errors.index?.message}
            helperText={errors.index?.message}
            label={`Index *`}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            {...register("name")}
            error={!!errors.name?.message}
            helperText={errors.name?.message}
            label={`Name *`}
            fullWidth
          />
        </Grid>
      </Grid>
    </ModalForm>
  );
};

export default EditCustomConsequenceCategoryModal;
