import { BAD_REQUEST, NOT_FOUND, FORBIDDEN } from "helpers/handleError";
import { Outlet, useParams, useSearchParams } from "react-router-dom";
import { ProjectAssetIdRouteProps } from "common/routeParams";
import { useGetNodes } from "api/node-analysis";
import { useGetProject } from "api/projects";
import ProjectNotFound from "components/ProjectNotFound";
import ProjectsBreadcrumbs from "./ProjectBreadcrumbs";
import { Container, Typography } from "@mui/material";

const ProjectWithBreadcrumbs = () => {
  const { assetId, projectId, nodeId } = useParams<ProjectAssetIdRouteProps>() as ProjectAssetIdRouteProps;
  const [searchParams] = useSearchParams();

  const { data: project, error } = useGetProject(assetId, projectId);
  const { data: nodes } = useGetNodes(projectId);

  if (nodeId && searchParams.get('tableOnly') === 'true') {
    const nodeName = nodes?.find((node) => node.id.toString() === nodeId)?.name || " loading ... ";
    return <>
      <Typography variant={"caption"}>{project?.name} / {nodeName}</Typography>
      <Outlet />
    </>;
  }

  const status = error?.response?.status;
  const showError = status === NOT_FOUND || status === BAD_REQUEST;
  if (status === FORBIDDEN) {
    return (
      <Container>
        <Typography variant="h3">No access</Typography>
      </Container>
    );
  }

  return (
    <>
      <ProjectsBreadcrumbs />
      {showError && <ProjectNotFound />}
      {!showError && <Outlet />}
    </>
  );
};

export default ProjectWithBreadcrumbs;
