import { useGetProfile } from "api/users";
import { useParams } from "react-router-dom";
import ParticipantsDatagrid from "./ParticipantsDatagrid";
import ParticipantsTitle from "./ParticipantsTitle";
import { useGetAsset } from "api/assets";
import { RoleEnum } from "helpers/isAuthorized";
import Loader from "components/Loader";
import { useMemo } from "react";

type Props = {};

type RouteParams = {
  assetId: string;
  projectId: string;
};

const Participants = (props: Props) => {
  const { assetId } = useParams<RouteParams>() as RouteParams;
  const { data: asset } = useGetAsset(assetId);
  const { data: profile } = useGetProfile();

  const organizationRoles: RoleEnum[] = useMemo(() => {
    if (!asset?.organizationId || !profile?.organizationRoles) return [];
    return profile?.organizationRoles?.filter((orgRoles) => orgRoles.organizationId === asset.organizationId)?.map((orgRoles) => orgRoles.roleName as RoleEnum) || []
  }, [asset, profile])

  if (!profile || !asset) return <Loader />;

  return (
    <>
      <ParticipantsTitle organizationRoles={organizationRoles} profile={profile} />
      <ParticipantsDatagrid organizationRoles={organizationRoles} profile={profile} />
    </>
  );
};

export default Participants;
