import { zodResolver } from "@hookform/resolvers/zod";
import { Grid, TextField } from "@mui/material";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { z } from "zod";
import { useAddDeviation } from "../../../api/deviations";
import ModalForm from "../../../components/ModalForm";

const DeviationSchema = z.object({
  guideword: z.string().min(1, "Guideword is required").max(256),
  parameter: z.string().min(1, "Parameter is required").max(512),
  interpretation: z.string().max(512).optional()
});

type NewDeviation = z.infer<typeof DeviationSchema>;

type Props = {
  isOpen: boolean;
  handleClose: () => void;
};

type RouteParams = {
  projectId: string;
};

const AddDeviationModal = ({ isOpen, handleClose }: Props) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    reset
  } = useForm<NewDeviation>({
    resolver: zodResolver(DeviationSchema)
  });

  const { projectId } = useParams<RouteParams>() as RouteParams;
  const { mutateAsync, isLoading, error } = useAddDeviation(projectId);

  useEffect(() => {
    if (error?.response?.data.guideword) {
      setError("guideword", {
        type: "string",
        message: error.response!.data.guideword
      });
    }
    if (error?.response?.data.parameter) {
      setError("parameter", {
        type: "string",
        message: error.response!.data.parameter
      });
    }
  }, [error, setError]);

  return (
    <ModalForm
      title={"Add Deviation"}
      isOpen={isOpen}
      handleClose={handleClose}
      onSubmit={handleSubmit(async (newDeviation) => {
        await mutateAsync(newDeviation);
        reset();
        handleClose();
      })}
      isLoading={isLoading}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            {...register("guideword")}
            error={!!errors.guideword?.message}
            helperText={errors.guideword?.message}
            label={`Guideword *`}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            {...register("parameter")}
            error={!!errors.parameter?.message}
            helperText={errors.parameter?.message}
            label={`Parameter *`}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            {...register("interpretation")}
            error={!!errors.interpretation?.message}
            helperText={errors.interpretation?.message}
            label={`Interpretation`}
            fullWidth
          />
        </Grid>
      </Grid>
    </ModalForm>
  );
};

export default AddDeviationModal;
