import { Divider } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { blue, grey } from "@mui/material/colors";
import { useGetProfile } from "api/users";
import Loader from "components/Loader";
import { isAuthorized, RoleEnum } from "helpers/isAuthorized";
import * as React from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "./logo.png";

function Header() {
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );

  const { data: profile, isLoading } = useGetProfile();
  const navigate = useNavigate();

  if (!profile || isLoading) return <Loader />;

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const logout = () => {
    if (process.env.NODE_ENV === "development") {
      window.location.href = `https://localhost:44497/logout`;
    } else {
      window.location.href = "/logout";
    }
  };

  return (
    <AppBar
      position="fixed"
      color="transparent"
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1,
        backgroundColor: (theme) => theme.palette.background.paper
      }}
    >
      <Container maxWidth={false}>
        <Toolbar disableGutters sx={{ justifyContent: "space-between" }}>
          <Link to="/">
            <img style={{ width: 100, height: 60 }} src={logo} alt="dnv-logo" />
          </Link>

          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography
              color="#0f204b"
              fontFamily="Broader View"
              sx={{ fontWeight: "bold" }}
            >
              Smart-PHA
            </Typography>
            <Divider
              orientation="vertical"
              flexItem
              sx={{
                margin: "0 10px",
                width: "2px",
                backgroundColor: grey[500]
              }}
            />

            <Box sx={{ flexGrow: 0 }}>
              <Tooltip title="Open settings">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Avatar sx={{ bgcolor: blue[800] }}>
                    {/* {getInitials(profile!.name)} */}
                  </Avatar>
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: "45px" }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right"
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right"
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                <Box p={2}>
                  <Typography variant="h6" pb={1}>
                    {profile?.userName}
                  </Typography>
                  <Typography fontWeight={"bold"}>
                    {profile?.roleName}
                  </Typography>
                </Box>
                <Divider />
                {isAuthorized({
                  role: profile.roleName as RoleEnum,
                  requiredRoles: [RoleEnum.Administrator]
                }) && (
                    <MenuItem
                      onClick={() => {
                        navigate("/admin-panel");
                        handleCloseUserMenu();
                      }}
                    >
                      <Typography textAlign="center">Admin Panel</Typography>
                    </MenuItem>
                  )}
                <MenuItem
                  onClick={() => {
                    logout();
                    handleCloseUserMenu();
                  }}
                >
                  <Typography textAlign="center">Logout</Typography>
                </MenuItem>
              </Menu>
            </Box>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default Header;
