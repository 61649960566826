import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import {
  Box,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@mui/material";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { Likelihood } from "types";
import {
  useDeleteLikelihood,
  useGetSelectedMatrixLikelihoods
} from "../../../../../api/likelihood";
import ConfirmationDialog from "../../../../../components/ConfirmationDialog";
import TitleWithAddButton from "../../../../../components/TitleWithAddButton";
import AddLikelihoodModal from "./AddLikelihoodModal";
import EditLikelihoodModal from "./EditLikelihoodModal";

type RouteParams = {
  projectId: string;
  assetId: string;
};

type Props = {
  isReadOnly: boolean;
  isDnv?: boolean;
};

const CustomLikelihoods = ({ isReadOnly, isDnv }: Props) => {
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] =
    useState(false);
  const [selectedCustomLikelihood, setSelectedCustomLikelihood] =
    useState<Likelihood>();

  const { projectId } = useParams<RouteParams>() as RouteParams;

  const { data: customLikelihoods } =
    useGetSelectedMatrixLikelihoods(projectId);
  const { mutateAsync: deleteLikelihood } = useDeleteLikelihood(
    projectId as string
  );

  return (
    <Box sx={{ marginTop: "4rem", marginBottom: "2rem" }}>
      <TitleWithAddButton
        title={"Likelihood index"}
        textUnderTitle={""}
        modal={
          <AddLikelihoodModal
            isOpen={isAddModalOpen}
            handleClose={() => setIsAddModalOpen(false)}
          />
        }
        openModal={() => setIsAddModalOpen(true)}
        buttonText={"Add likelihood"}
        titleVariant="h4"
        isButtonVisible={!isDnv && !isReadOnly}
      />

      <TableContainer component={Paper}>
        <Table sx={{ tableLayout: "fixed" }}>
          <TableHead>
            <TableRow>
              <TableCell sx={{ width: "10%" }}>Index</TableCell>
              <TableCell sx={{ width: "30%" }}>Name</TableCell>
              <TableCell sx={{ width: "50%" }}>Description</TableCell>
              {!isDnv && !isReadOnly && (
                <>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                </>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {customLikelihoods?.map((customLikelihood) => (
              <TableRow key={customLikelihood.name}>
                <TableCell>{customLikelihood.index}</TableCell>
                <TableCell>{customLikelihood.name}</TableCell>
                <TableCell>{customLikelihood.description}</TableCell>
                {!isDnv && !isReadOnly && (
                  <>
                    <TableCell align="right" sx={{ padding: "4px" }}>
                      <IconButton
                        onClick={() => {
                          setIsDeleteConfirmationOpen(true);
                          setSelectedCustomLikelihood(customLikelihood);
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                    <TableCell
                      align="right"
                      sx={{ padding: "4px", background: "#fff" }}
                    >
                      <IconButton
                        onClick={() => {
                          setIsEditModalOpen(true);
                          setSelectedCustomLikelihood(customLikelihood);
                        }}
                      >
                        <EditIcon />
                      </IconButton>
                    </TableCell>
                  </>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {selectedCustomLikelihood && isEditModalOpen && (
        <EditLikelihoodModal
          isOpen={isEditModalOpen}
          handleClose={() => {
            setIsEditModalOpen(false);
            setSelectedCustomLikelihood(undefined);
          }}
          likelihood={selectedCustomLikelihood}
        />
      )}

      <ConfirmationDialog
        isOpen={isDeleteConfirmationOpen}
        handleClose={() => setIsDeleteConfirmationOpen(false)}
        title={"Delete custom likelihood"}
        text={"Are you sure you want to delete custom likelihood?"}
        onAgree={() => {
          deleteLikelihood(selectedCustomLikelihood!.id);
          setIsDeleteConfirmationOpen(false);
        }}
      />
    </Box>
  );
};

export default CustomLikelihoods;
