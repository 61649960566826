import { zodResolver } from "@hookform/resolvers/zod";
import { Autocomplete, Grid, TextField } from "@mui/material";
import { RoleEnum } from "helpers/isAuthorized";
import { Controller, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { z } from "zod";
import { useGetAppRoles } from "../../api/roles";
import { useAddUser, useGetDnvUsersEmails } from "../../api/users";
import ModalForm from "../../components/ModalForm";
import useDebounce from "../../hooks/useDebounce";

const UserSchema = z.object({
  email: z.string().max(256),
  roleId: z.number()
});

type NewUserType = z.infer<typeof UserSchema>;

type Props = {
  isOpen: boolean;
  handleClose: () => void;
  usersOnTheList: string[];
};
type RouteParams = {
  organizationId: string;
};
const ALLOWED_ROLES = [RoleEnum.OrganizationOwner, RoleEnum.OrganizationReader];

const AddUserModal = ({ isOpen, handleClose, usersOnTheList }: Props) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
    watch,
    reset
  } = useForm<NewUserType>({
    resolver: zodResolver(UserSchema),
    defaultValues: {
      email: ""
    }
  });
  const email = watch("email");
  const debouncedSearchQuery = useDebounce(email, 500);

  const { data: dnvUsersEmails, isFetching: isFetchingDnvUsers } =
    useGetDnvUsersEmails(debouncedSearchQuery);
  const { data: roles, isFetching: isFetchingRoles } = useGetAppRoles();

  const { organizationId } = useParams<{ organizationId: string }>() as RouteParams;
  const { mutateAsync: addUser, isLoading } = useAddUser(organizationId);

  const emailsExist = dnvUsersEmails && dnvUsersEmails.length > 0;
  const isDebouncedValueChanged = debouncedSearchQuery !== email;

  console.log(debouncedSearchQuery, usersOnTheList.includes(debouncedSearchQuery))

  const emailError =
    !isFetchingDnvUsers && !isDebouncedValueChanged && !emailsExist && email.length > 0;
  const emailAlreadyExists = usersOnTheList.includes(debouncedSearchQuery);

  return (
    <ModalForm
      title={"Add user"}
      isOpen={isOpen}
      handleClose={() => { reset(); handleClose(); }}
      onSubmit={handleSubmit(async (newUser) => {
        if (!(emailAlreadyExists || emailError)) {
          await addUser(newUser);
          reset();
          handleClose();
        }
      })}
      isLoading={isLoading}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Controller
            control={control}
            name="email"
            defaultValue={""}
            render={({ field: { onChange, value } }) => {
              return (
                <Autocomplete
                  onChange={(_, newEmail) => {
                    onChange(newEmail ?? "");
                  }}
                  loading={isFetchingDnvUsers}
                  value={value}
                  options={dnvUsersEmails ?? []}
                  defaultValue={""}
                  freeSolo
                  onInputChange={(_, newEmail) => {
                    onChange(newEmail);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Email *"
                      error={emailAlreadyExists || emailError}
                      helperText={
                        errors.email?.message ||
                        (emailAlreadyExists && `${value} is added to this organization`) ||
                        (emailError && `${value} is not subscribed to Smart-PHA through veracity.`)
                      }
                    />
                  )}
                />
              );
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            control={control}
            name="roleId"
            // @ts-ignore need to set value to null to have a controlled input
            defaultValue={null}
            render={({ field: { onChange, value } }) => {
              return (
                <Autocomplete
                  onChange={(event, newAssetTypeId) => {
                    onChange(newAssetTypeId);
                  }}
                  loading={isFetchingRoles}
                  value={value}
                  options={
                    roles
                      ?.filter((role) =>
                        ALLOWED_ROLES.includes(role.name as RoleEnum)
                      )
                      .map((role) => role.id) ?? []
                  }
                  defaultValue={null}
                  getOptionLabel={(option) =>
                    roles?.find((role) => role.id === option)?.name ?? ""
                  }
                  isOptionEqualToValue={(option, value) => {
                    return option === value;
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Role *"
                      error={!!errors.roleId}
                      helperText={errors.roleId?.message}
                    />
                  )}
                />
              );
            }}
          />
        </Grid>
      </Grid>
    </ModalForm>
  );
};

export default AddUserModal;
