import CustomRiskClassification from "./custom/classifications/CustomRiskClassification";
import CustomConsequenceCategories from "./custom/consequence-categories/CustomConsequenceCategories";
import CustomLikelihoods from "./custom/likelihood/CustomLikelihoods";
import CustomRiskRank from "./custom/risk-ranks/CustomRiskRank";
import CustomSeverity from "./custom/severity/CustomSeverity";

type Props = {
  isReadOnly: boolean;
}

const DnvRiskMatrix = ({isReadOnly}:Props) => {
  return (
    <>
      <CustomRiskRank isDnv isReadOnly />
      <CustomConsequenceCategories isDnv isReadOnly />
      <CustomSeverity isDnv isReadOnly/>
      <CustomLikelihoods isDnv isReadOnly />
      <CustomRiskClassification isDnv isReadOnly />
    </>
  );
};

export default DnvRiskMatrix;
