import axios from "axios";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { handleError } from "../helpers/handleError";
import {
  AssetProperties,
  AssetPropertiesAddDto,
  AssetPropertiesEditDto,
  AssetTypeDto
} from "../types";

export const useGetAssetProperties = (projectId: string) => {
  return useQuery(`asset-properties-${projectId}`, async () => {
    const { data } = await axios.get<AssetProperties>(
      `/api/assetproperties/projects/${projectId}`
    );
    return data;
  },
  {
    onError: handleError<unknown>
  });
};

export const useGetAssetTypes = () => {
  return useQuery(`asset-types`, async () => {
    const { data } = await axios.get<AssetTypeDto[]>(`/api/assettypes`);
    return data;
  },
  {
    onError: handleError<unknown>
  });
};

const addAssetProperty = async (
  projectId: string,
  newAssetProperties: AssetPropertiesAddDto
) => {
  const { data: assetProperties } = await axios.post<AssetProperties>(
    `/api/assetproperties/projects/${projectId}`,
    newAssetProperties
  );
  return assetProperties;
};

export const useAddAssetProperty = (projectId: string) => {
  const queryClient = useQueryClient();

  return useMutation(
    (newAssetProperties: AssetPropertiesAddDto) =>
      addAssetProperty(projectId, newAssetProperties),
    {
      onError: handleError<unknown>,
      onSuccess: () => {
        toast.success("Successfully added new asset properties.");
        queryClient.invalidateQueries(`asset-properties-${projectId}`);
      }
    }
  );
};

const updateAssetProperty = async (
  projectId: string,
  assetPropertyId: number,
  newAssetProperties: AssetPropertiesEditDto
) => {
  const { data: assetProperties } = await axios.put<AssetProperties>(
    `/api/assetproperties/${assetPropertyId}/projects/${projectId}`,
    newAssetProperties
  );
  return assetProperties;
};

export type UseUpdateAssetPropertyParams = {
  newAssetProperties: AssetPropertiesEditDto;
  assetPropertyId: number;
};

export const useUpdateAssetProperty = (projectId: string) => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ newAssetProperties, assetPropertyId }: UseUpdateAssetPropertyParams) =>
      updateAssetProperty(projectId, assetPropertyId, newAssetProperties),
    {
      onError: handleError<unknown>,
      onSuccess: () => {
        toast.success("Successfully updated asset properties.");
        queryClient.invalidateQueries(`asset-properties-${projectId}`);
      }
    }
  );
};
