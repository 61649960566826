import { UniqueIdentifier } from "@dnd-kit/core";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { TableRow } from "@mui/material";

export default function TableRowWrapper({
  id,
  children
}: {
  id: UniqueIdentifier;
  children: JSX.Element | JSX.Element[];
}) {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({
      id
    });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition
  };

  return (
    <TableRow ref={setNodeRef} style={style} {...attributes} {...listeners}>
      {children}
    </TableRow>
  );
}
