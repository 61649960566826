import { fabric } from "fabric";
import { LineCoords } from "../Lines";

export const RECTANGLE_WIDTH = 400;
export const RECTANGLE_HEIGHT = 200;

export interface TextCoords {
  id: number;
  x: number;
  y: number;
  width: number;
  height: number;
}

export default class Rectangles {
  constructor(
    private canvas: fabric.Canvas,
    private textCoords: TextCoords[]
  ) {}

  drawRectangle(
    left: number,
    top: number,
    width: number,
    height: number,
    color: string,
    lockMovement = false,
    id = -1
  ) {
    const rectangle = new fabric.Rect({
      name: `rectangle_${id}`,

      left,
      top,
      width,
      height,

      stroke: color,
      fill: "",
      strokeWidth: 10,
      opacity: 0.5,

      lockMovementX: lockMovement,
      lockMovementY: lockMovement,
      lockScalingX: lockMovement,
      lockScalingY: lockMovement,
      lockUniScaling: lockMovement
    });
    rectangle.setControlsVisibility({ mtr: false });
    this.canvas.add(rectangle);

    return rectangle;
  }
  isBetweenX(x: number, x1: number, x2: number, extend: number = 250) {
    if (x1 < x2) {
      return x >= x1 - extend && x <= x2 + extend;
    }

    return x >= x2 - extend && x <= x1 + extend;
  }

  isBetweenY(y: number, y1: number, y2: number, extend: number = 250) {
    if (y1 < y2) {
      return y >= y1 - extend && y <= y2 + extend;
    }

    return y >= y2 - extend && y <= y1 + extend;
  }

  isTextAboveOrBelow(lineCoords: LineCoords, textCoords: TextCoords) {
    return (
      this.isBetweenY(textCoords.y, lineCoords.y1, lineCoords.y2) &&
      (Math.abs(lineCoords.x1 - textCoords.x) < 250 ||
        Math.abs(lineCoords.x2 - textCoords.x) < 250)
    );
  }

  isTextLeftOrRight(lineCoords: LineCoords, textCoords: TextCoords) {
    return (
      this.isBetweenX(textCoords.x, lineCoords.x1, lineCoords.x2) &&
      (Math.abs(lineCoords.y1 - textCoords.y) < 250 ||
        Math.abs(lineCoords.y2 - textCoords.y) < 250)
    );
  }

  findSymbolSInProximity(lineCoords: LineCoords) {
    return this.textCoords.filter((textCoords) => {
      // Draw horizontal line and check if any symbol is above or below
      if (
        this.isTextAboveOrBelow(lineCoords, textCoords) ||
        this.isTextLeftOrRight(lineCoords, textCoords)
      ) {
        return true;
      }

      return false;
    });
  }
}
