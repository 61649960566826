import TitleWithAddButton from "components/TitleWithAddButton";
import { isAuthorized, RoleEnum } from "helpers/isAuthorized";
import { ViewedUser } from "types";
import { useState } from "react";
import AddParticipantModal from "./add-edit-participant/AddParticipantModal";

type Props = {
  organizationRoles: RoleEnum[];
  profile: ViewedUser | undefined;
};

const ParticipantsTitle = (props: Props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <TitleWithAddButton
      title={"Participants"}
      openModal={() => setIsModalOpen(true)}
      buttonText={"Add participant"}
      modal={
        <AddParticipantModal
          isOpen={isModalOpen}
          handleClose={() => setIsModalOpen(false)}
        />
      }
      textUnderTitle={"Add and manage workshop participants"}
      isButtonVisible={isAuthorized({
        role: props.profile?.roleName as RoleEnum,
        organizationRoles: props.organizationRoles,
        requiredRoles: [RoleEnum.Administrator, RoleEnum.OrganizationOwner]
      })}
    />
  );
};

export default ParticipantsTitle;
