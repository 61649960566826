import ArticleIcon from "@mui/icons-material/Article";
import DownloadIcon from "@mui/icons-material/Download";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import SelectAllIcon from "@mui/icons-material/SelectAll";
import WebAssetIcon from "@mui/icons-material/WebAsset";
import { useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Toolbar from "@mui/material/Toolbar";
import { downloadBlob } from "helpers/commonHelpers";
import { Link, useLocation, useParams } from "react-router-dom";
import DocumentCategoriesAccordion from "./DocumentCategoriesAccordion";

const drawerWidth = 260;

type Props = { children: JSX.Element };

type RouteProps = {
  projectId: string;
  assetId: string;
};

const ITEMS = [
  {
    to: "properties",
    label: "Asset properties",
    icon: <WebAssetIcon />
  },
  {
    to: "hazop",
    label: "HAZOP worksheet",
    icon: <ArticleIcon />
  },
  {
    to: "deviations",
    label: "Deviation list",
    icon: <FormatListBulletedIcon />
  },
  {
    to: "riskmatrix",
    label: "Risk matrix selection",
    icon: <SelectAllIcon />
  }
];

export default function Sidebar({ children }: Props) {
  const { projectId, assetId } = useParams<{
    projectId: string;
    assetId: string;
  }>() as RouteProps;
  const location = useLocation();
  const theme = useTheme();
  const drawerStyles = {
    [theme.breakpoints.down(1700)]: {
      width: drawerWidth
    },
    width: drawerWidth
  };

  const downloadReport = () => {
    downloadBlob("terms-of-reference", `/api/tor/report/${projectId}`, {
      type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
    });
  };

  return (
    <Box sx={{ display: "flex" }}>
      <Drawer
        variant="permanent"
        sx={{
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: {
            width: drawerWidth,
            boxSizing: "border-box"
          },
          ...drawerStyles
        }}
      >
        <Toolbar />
        <Box sx={{ overflow: "auto" }}>
          <List>
            {ITEMS.map((item, index) => (
              <Link
                to={`/assets/${assetId}/projects/${projectId}/studysetup/${item.to}`}
                style={{ textDecoration: "none", color: "inherit" }}
                key={item.label}
              >
                <ListItem key={item.to} disablePadding>
                  <ListItemButton
                    selected={location.pathname.includes(item.to)}
                  >
                    <ListItemIcon sx={{ minWidth: "30px" }}>
                      {item.icon}
                    </ListItemIcon>
                    <ListItemText primary={item.label} />
                  </ListItemButton>
                </ListItem>
              </Link>
            ))}
            <DocumentCategoriesAccordion />
            <ListItem disablePadding onClick={downloadReport}>
              <ListItemButton>
                <ListItemIcon sx={{ minWidth: "30px" }}>
                  <DownloadIcon />
                </ListItemIcon>
                <ListItemText primary={"Terms of reference"} />
              </ListItemButton>
            </ListItem>
            {/* </Link> */}
          </List>
        </Box>
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, minHeight: "100vh" }}>
        {children}
      </Box>
    </Box>
  );
}
