import { Box, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import {
  RawScenarioRiskClassification,
  useGetMatrixClassifications,
  useUpsertMatrixClassification
} from "api/classifications";
import { useGetSelectedMatrixLikelihoods } from "api/likelihood";
import {
  useGetSelectedMatrix,
  useGetSelectedMatrixRiskRanks
} from "api/matrixes";
import { SeverityDto, useGetSelectedSeverity } from "api/severity";
import Loader from "components/Loader";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { Likelihood } from "types";
import { CELL_LENGTH, OFFSET } from "./constants";

type SquareProps = {
  color: string;
  text: string;
  square?: RawScenarioRiskClassification;
  onClick: () => void;
};

const Square = ({ color, text, onClick }: SquareProps) => {
  return (
    <Box
      sx={{
        display: "flex",
        width: CELL_LENGTH,
        height: CELL_LENGTH,
        backgroundColor: color,
        border: `1px solid #fff`,
        justifyContent: "center",
        alignItems: "center"
      }}
      onClick={onClick}
    >
      {text}
    </Box>
  );
};

type RouteProps = {
  projectId: string;
};

type Props = {
  isReadOnly: boolean;
  isDnv?: boolean;
};

const Squares = ({ isReadOnly, isDnv }: Props) => {
  const { projectId } = useParams<RouteProps>() as RouteProps;
  const { data: selectedMatrix } = useGetSelectedMatrix(projectId);
  const { data: classifications } = useGetMatrixClassifications(
    projectId,
    selectedMatrix?.id
  );
  const { data: likelihoods } = useGetSelectedMatrixLikelihoods(projectId);
  const { data: severities } = useGetSelectedSeverity(projectId);
  const { data: riskRanks } = useGetSelectedMatrixRiskRanks(projectId);

  const { mutateAsync: updateClassification } = useUpsertMatrixClassification(
    projectId,
    selectedMatrix?.id
  );

  const [selectedRiskRankId, setSetselectedRiskRankId] = useState(0);

  if (!likelihoods || !severities || !classifications || !riskRanks)
    return <Loader />;

  const dimensions = severities.length;
  const width = dimensions * CELL_LENGTH;

  const cells = prepareMatrix(classifications, severities, likelihoods);

  function prepareMatrix(classifications: RawScenarioRiskClassification[], severities: SeverityDto[], likelihoods: Likelihood[]): (RawScenarioRiskClassification)[][] {
    return likelihoods.map(l => {
      return severities.map(s => classifications.find(c => c.likelihoodId === l.id && c.severityId === s.id) || { likelihoodId: l.id, severityId: s.id } as RawScenarioRiskClassification)
    })
  }

  const handleUpdateClassification = async (
    classification: RawScenarioRiskClassification,
    newRiskRankId: number
  ) => {
    await updateClassification({
      ...classification,
      riskRankId: newRiskRankId
    });
  };

  return (
    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
      <Box
        sx={{
          height: width,
          display: "flex",
          flexWrap: "wrap",
          flexDirection: "column"
        }}
      >
        {severities.map((severity) => {
          return (
            <Typography
              key={severity.id}
              sx={{
                width: OFFSET,
                height: CELL_LENGTH,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: grey[700],
                borderLeft: `1px solid ${grey[500]}`
              }}
            >
              {severity.index}
            </Typography>
          );
        })}
        {cells.map((squares) =>
          squares.map((square, i) => {
            const riskRank = riskRanks.find(
              (riskRank) => riskRank.id === square.riskRankId
            );
            return (
              <Square
                key={`${square.likelihoodId}-${square.severityId}`}
                color={riskRank?.color || "white"}
                text={riskRank?.name || "N/A"}
                onClick={() => {
                  if (selectedRiskRankId) {
                    handleUpdateClassification(square, selectedRiskRankId);
                  }
                }}
              />
            );
          })
        )}
      </Box>

      {!isDnv && !isReadOnly && (
        <Box sx={{ display: "flex", flexDirection: "column-reverse" }}>
          {riskRanks.map((riskRank) => {
            return (
              <Square
                key={riskRank.id}
                color={riskRank.color}
                text={riskRank.name}
                onClick={() => setSetselectedRiskRankId(riskRank.id)}
              />
            );
          })}
        </Box>
      )}
    </Box>
  );
};

export default Squares;
