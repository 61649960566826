import { zodResolver } from "@hookform/resolvers/zod";
import { Grid, TextField } from "@mui/material";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { Likelihood } from "types";
import { useEditLikelihood } from "../../../../../api/likelihood";
import ModalForm from "../../../../../components/ModalForm";
import { LikelihoodSchema, LikelihoodType } from "./AddLikelihoodModal";

type Props = {
  isOpen: boolean;
  handleClose: () => void;
  likelihood: Likelihood;
};

type RouteParams = {
  projectId: string;
  assetId: string;
};

const EditLikelihoodModal = ({ isOpen, handleClose, likelihood }: Props) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset
  } = useForm<LikelihoodType>({
    resolver: zodResolver(LikelihoodSchema),
    defaultValues: likelihood
  });
  const { projectId } = useParams<RouteParams>() as RouteParams;
  const { mutateAsync: editLikelihood, isLoading } = useEditLikelihood(
    projectId,
    likelihood.id
  );

  return (
    <ModalForm
      title={"Edit custom likelihood"}
      isOpen={isOpen}
      handleClose={handleClose}
      onSubmit={handleSubmit(async (newLikelihood) => {
        await editLikelihood(newLikelihood);
        reset();
        handleClose();
      })}
      isLoading={isLoading}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            {...register("index", {
              valueAsNumber: true
            })}
            inputMode="numeric"
            error={!!errors.index?.message}
            helperText={errors.index?.message}
            label={`Index *`}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            {...register("name")}
            error={!!errors.name?.message}
            helperText={errors.name?.message}
            label={`Name *`}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            {...register("description")}
            error={!!errors.description?.message}
            helperText={errors.description?.message}
            label={`Description *`}
            fullWidth
          />
        </Grid>
      </Grid>
    </ModalForm>
  );
};

export default EditLikelihoodModal;
