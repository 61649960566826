import { Container, Typography } from "@mui/material";
import { useGetProfile } from "api/users";
import { isAuthorized, RoleEnum } from "helpers/isAuthorized";
import Loader from "./Loader";

type Props = {
  requiredRoles: RoleEnum[];
  children: JSX.Element;
};

const ProtectedRoute = ({ requiredRoles, children }: Props) => {
  const { data: profile, isLoading } = useGetProfile();

  if (isLoading || !profile) return <Loader />;
  if (isAuthorized({ role: profile.roleName as RoleEnum, requiredRoles }))
    return children;

  return (
    <Container>
      <Typography variant="h3">No access</Typography>
    </Container>
  );
};

export default ProtectedRoute;
