import { zodResolver } from "@hookform/resolvers/zod";
import { Grid, TextField } from "@mui/material";
import { useGetSelectedMatrixConsequenceCategories } from "api/matrixes";
import { useAddCustomSeverity } from "api/severity";
import { useEffect } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { z } from "zod";
import ModalForm from "../../../../../components/ModalForm";

export const SeverityIndex = z.object({
  index: z.number({ required_error: "Index is required" }),
  name: z.string({ required_error: "Name is required" }),
  columns: z
    .object({
      value: z.object({
        id: z.number(),
        name: z.string(),
        description: z.string()
      })
    })
    .array()
});

export type SeverityIndexType = z.infer<typeof SeverityIndex>;

type Props = {
  isOpen: boolean;
  handleClose: () => void;
};

type RouteParams = {
  projectId: string;
  assetId: string;
};

const AddSeverityModal = ({ isOpen, handleClose }: Props) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setValue
  } = useForm<z.infer<typeof SeverityIndex>>({
    resolver: zodResolver(SeverityIndex)
  });

  const { projectId } = useParams<RouteParams>() as RouteParams;

  const { mutateAsync: addCustomSeverity, isLoading } =
    useAddCustomSeverity(projectId);
  const { data: consequenceCategories } =
    useGetSelectedMatrixConsequenceCategories(projectId);

  const { fields, append } = useFieldArray({
    name: "columns",
    control
  });

  const onChange = (index: number, description: string) => {
    setValue(`columns.${index}.value.description`, description);
  };

  useEffect(() => {
    consequenceCategories?.forEach((consequenceCategory) => {
      append({
        value: {
          id: consequenceCategory.id,
          name: `${consequenceCategory.name}`,
          description: ""
        }
      });
    });

    return () => {
      reset();
    };
  }, [consequenceCategories, append, reset]);

  return (
    <ModalForm
      title={"Add severity index"}
      isOpen={isOpen}
      handleClose={handleClose}
      onSubmit={handleSubmit(async (newCustomSeverityIndex) => {
        const { index, name } = newCustomSeverityIndex;
        const body = {
          index,
          name,
          consequenceCategoriesValues: newCustomSeverityIndex.columns.reduce(
            (acc, next, i) => {
              if (!next) return acc;
              return {
                ...acc,
                [next.value.id]: next.value.description
              };
            },
            {}
          )
        };
        await addCustomSeverity(body);
        reset();
        handleClose();
      })}
      isLoading={isLoading}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            {...register("index", {
              valueAsNumber: true
            })}
            inputMode="numeric"
            error={!!errors.index?.message}
            helperText={errors.index?.message}
            label={`Index *`}
            fullWidth
            type="number"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            {...register("name")}
            error={!!errors.name?.message}
            helperText={errors.name?.message}
            label={`Name *`}
            fullWidth
          />
        </Grid>
        {fields?.map((field, index) => {
          return (
            <Grid item xs={12} key={field.id}>
              <Controller
                render={(field2) => {
                  return (
                    <TextField
                      label={`${field.value.name} *`}
                      onChange={(event) => onChange(index, event.target.value)}
                      fullWidth
                    />
                  );
                }}
                name={`columns.${index}.value.name` as const}
                control={control}
              />
            </Grid>
          );
        })}
      </Grid>
    </ModalForm>
  );
};

export default AddSeverityModal;
