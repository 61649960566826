import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import { Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { useTheme } from "@mui/material/styles";

type Props = { fileName: string };

const FileWithIcon = ({ fileName }: Props) => {
  const theme = useTheme();
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginRight: theme.spacing(1)
      }}
    >
      <InsertDriveFileIcon style={{ fontSize: 35, color: grey[500] }} />
      <Typography
        variant="body2"
        align="center"
        style={{ maxWidth: 100, marginTop: theme.spacing(1) }}
      >
        {fileName}
      </Typography>
    </div>
  );
};

export default FileWithIcon;
