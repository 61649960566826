import { Container, Typography } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { Box, InputAdornment, TextField } from "@mui/material";
import { RoleEnum, isAuthorized } from "helpers/isAuthorized";
import { useGetOrganizationUsers, useGetProfile } from "api/users";
import { useGetOrganizations } from "api/organizations";
import Loader from "components/Loader";
import useDebounce from "hooks/useDebounce";

import { useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import AddUserModal from "./AddUserModal.tsx";
import TitleWithAddButton from "../../components/TitleWithAddButton";

import UsersDatagrid from "modules/admin/UsersDatagrid";

type Props = {};

type RouteParams = {
  organizationId: string;
};

const Users = (props: Props) => {
  const { organizationId } = useParams<RouteParams>() as RouteParams;
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [query, setQuery] = useState("");
  const debouncedQuery = useDebounce(query.toLowerCase());

  const { data: profile } = useGetProfile();
  const { data: users } = useGetOrganizationUsers(organizationId);
  const { data: organizations } = useGetOrganizations();

  const organizationName = useMemo(
    () => organizations?.find((org) => org.id.toString() === organizationId)?.companyName || '',
    [organizationId, organizations]
  );

  const filteredUsers = useMemo(
    () =>
      users?.filter((user) => {
        return (
          user.userName?.toLowerCase().includes(debouncedQuery.toLowerCase()) ||
          user.roleName?.toLowerCase().includes(debouncedQuery.toLowerCase()) ||
          user.email?.toLowerCase().includes(debouncedQuery.toLowerCase())
        );
      }) ?? [],
    [users, debouncedQuery]
  );

  if (!profile) return <Loader />;

  const organizationRoles: RoleEnum[] = profile.organizationRoles?.filter(
    (orgRoles) => orgRoles.organizationId.toString() === organizationId
  ).map(
    (orgRoles) => orgRoles.roleName as RoleEnum
  ) || [];

  const hasAccess = isAuthorized({
    role: profile?.roleName as RoleEnum,
    organizationRoles: organizationRoles,
    requiredRoles: [RoleEnum.Administrator, RoleEnum.GlobalReader, RoleEnum.OrganizationOwner, RoleEnum.OrganizationReader]
  })

  if (!hasAccess) {
    return (
      <Container>
        <Typography variant="h3">No access</Typography>
      </Container>
    );
  }

  if (!users) return <Loader />;

  const isEditable = isAuthorized({
    role: profile?.roleName as RoleEnum,
    organizationRoles: organizationRoles,
    requiredRoles: [RoleEnum.Administrator, RoleEnum.OrganizationOwner]
  })

  return (
    <>
      <TitleWithAddButton
        title={`Organization ${organizationName} - Users`}
        textUnderTitle={"Add and manage users"}
        modal={
          <AddUserModal
            isOpen={isModalOpen}
            handleClose={() => setIsModalOpen(false)}
            usersOnTheList={filteredUsers.map(user => user.email)}
          />
        }
        openModal={() => setIsModalOpen(true)}
        buttonText={"Add user"}
        isButtonVisible={isEditable}
      />
      <Box
        sx={{
          display: "flex",
          alignItems: "center",

          justifyContent: "flex-end",
          marginBottom: "0.5rem"
        }}
      >
        <TextField
          size="small"
          value={query}
          onChange={(e) => {
            setQuery(e.target.value);
            // setPage(1);
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            )
          }}
        />
      </Box>

      <UsersDatagrid users={filteredUsers} isEditable={isEditable} />
    </>
  );
};

export default Users;
