import DeleteIcon from "@mui/icons-material/Delete";
import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@mui/material";

type Props = {};

const DrawingListDetailsTable = (props: Props) => {
  // const { data: drawingTags } = useGetDrawingTags(1);
  const drawingTags = [] as any[];

  if (!drawingTags) return <Typography>No tags</Typography>;

  return (
    <TableContainer component={Paper}>
      <Table aria-label="a dense table" sx={{ tableLayout: "fixed" }}>
        <TableHead>
          <TableRow>
            <TableCell>Tag</TableCell>
            <TableCell>Name</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {drawingTags.map((drawingTag) => (
            <TableRow
              key={drawingTag.name}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell>{drawingTag.tag}</TableCell>
              <TableCell>{drawingTag.name}</TableCell>
              <TableCell align="right">
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    // setSelectedDeviation(deviation);
                    // setIsDeleteConfirmationOpen(true);
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default DrawingListDetailsTable;
